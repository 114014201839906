@import '../../../assets/scss/variables';

.separate-modal-select-cameras {
  &__header {
    position: relative;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    &__title {
      font-size: 20px;
      font-weight: 800;
    }
    &__back-button {
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px;
      border-radius: 22px;
      color: $accent-color-1;
      &:hover {
        background-color: $accent-color-5;
      }
      &:active {
        color: $accent-color-3;
        background-color: $accent-color-4;
      }
    }
  }
  .cameras-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
    &__loader {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__info {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.5;
    }
  }
  .camera-title {
    position: absolute;
    bottom: 12px;
    left: 12px;
    padding: 4px 8px;
    max-width: calc(100% - 24px);
    color: $color-white;
    background-color: $player-title-background;
    border-radius: 4px;
    border: 1px solid $color-white;
    z-index: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .camera-indicator {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 10px;
    height: 10px;
    background-color: $accent-color-1;
    border-radius: 8px;
    z-index: 3;
    &.online {
      background-color: $success-color-1;
    }
    svg {
      width: 10px;
      height: 10px;
    }
  }
  .camera-element {
    position: relative;
    width: 226px;
    height: 140px;
    background-color: $gray-color-body;
    border-radius: $border-radius-primary;
    &.active {
      border: 2px solid $accent-color-1;
    }
    .camera-amount-select {
      position: absolute;
      top: 8px;
      right: 8px;
    }
    .image-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-pink;
      z-index: 1;
      &.md {
        color: white;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
  .camera-preview {
    width: 100%;
    height: 100%;
    border-radius: $border-radius-primary;
  }
}
