@import '../../../assets/scss/variables';

.table-dropdown {
  margin: 0;
  transition: none;
  &.show {
    .dropdown-toggle {
      color: $gray-color-body !important;
      background-color: $gray-color-5 !important;
      &:hover {
        background-color: $gray-color-5;
      }
    }
  }
  &.small > .dropdown-toggle {
    padding: 0px;
  }
  .dropdown-toggle {
    padding: 10px;
    color: $gray-color-tertiary !important;
    background-color: transparent !important;
    border-radius: $border-radius-primary;
    box-shadow: none !important;
    border: none;
    outline: none;
    &::after {
      content: none !important;
    }
    &:hover {
      color: $gray-color-body;
      background-color: $gray-color-6;
    }
    &:active {
      color: $gray-color-body !important;
      background-color: $gray-color-5 !important;
    }
  }
}
.dropdown-menu {
  padding: 4px;
  border-radius: 4px;
  box-shadow: $shadow-dropdown-menu;
  transition: all 0s !important;
  // position: fixed !important;
  .dropdown-item {
    padding: 9px 12px;
    font-size: 14px;
    border-radius: 4px;
    &:hover {
      background-color: $gray-color-6;
    }
    &:active {
      background-color: $gray-color-5;
    }
  }
}
