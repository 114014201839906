@import '../../assets/scss/variables.scss';

.icon-view {
  &__container {
    display: grid;
    grid-gap: 24px 20px;
    grid-template-columns: repeat(4, 1fr);
    .icon-view__item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      gap: 12px;
      .icon-view__component {
        width: 100%;
        margin: auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .icon-view__description {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .icon-view__name {
          font-size: 14px;
          font-weight: 600;
          line-height: 17.5px;
        }
        .icon-view__time {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: $gray-color-2;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          svg {
            width: 16px;
            height: 16px;
          }
          &-date,
          &-clock {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
      }
    }
  }
  &-restaurant__container {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    .restaurant-view__item {
      border: 1px solid $gray-color-1;
      background-color: $gray-color-5;
      border-radius: 18px;
      min-height: 132px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      &.normal {
        border-color: $success-color-1;
        background-color: $success-color-6;
      }
      &.warning {
        border-color: $warning-color-1;
        background-color: $warning-color-6;
      }
      &.error {
        border-color: $danger-color-1;
        background-color: $danger-color-6;
      }
      &-controls {
        display: flex;
        width: 100%;
        justify-content: space-between;
        &__item {
          display: flex;
          gap: 8px;
        }
        .restaurant-view__control {
          background-color: $accent-color-1;
          border-radius: 18px;
          height: 36px;
          min-width: 36px;
          color: $color-white;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
          cursor: pointer;
          transition: 0.3s;
          &.disabled {
            cursor: default;
            &:hover {
              background-color: $accent-color-1;
            }
            &:active {
              color: $color-white;
            }
          }
          &-devices {
            padding: 8px 20px 8px 14px;
          }
          svg {
            width: 20px;
            height: 20px;
          }
          &:hover {
            background-color: $accent-color-2;
          }
          &:active {
            color: $danger-color-1;
          }
        }
      }
      .restaurant-view__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        padding-bottom: 8px;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: $gray-color-secondary;
        &-title {
          font-size: 24px;
          font-weight: 800;
          line-height: 30px;
          color: $gray-color-1;
        }
      }
    }
  }
  &__loader {
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__pagination {
    border: none;
    padding: 16px 24px;
  }
}

@media (max-width: $xs) {
  .icon-view {
    &__container {
      grid-template-columns: repeat(1, 1fr);
    }
    &-restaurant__container {
      grid-template-columns: repeat(1, 1fr);
    }
    &__pagination {
      padding: 16px 0px;
    }
  }
}
