@import '../../../assets/scss/variables';

.custom-cell__value-with-change {
  .value-change-percent {
    white-space: nowrap;
    color: $gray-color-tertiary;

    &.positive {
      color: $success-color-1;
    }
    &.negative {
      color: $accent-color-1;
    }
  }
}
