@import '../../../assets/scss/variables.scss';

.form-input {
  height: 44px;
  width: 100%;
  background-color: $gray-color-6;
  border-radius: 18px;
  padding: 13px 12px;
  position: relative;
  font-size: 16px;
  &.invalid {
    background-color: $color-white !important;
    border: 1px solid $accent-color-1;
  }
  &.with__title {
    padding: 20px 12px 5px;
    &.extra_label_padding {
      height: auto;
      padding-top: 35px;
    }
  }
  &[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    padding-right: 35px;
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &__with-margin {
    margin-bottom: 12px;
  }
  &__container {
    position: relative;
    &.disabled {
      pointer-events: none;
      cursor: default;
      span {
        left: 0;
      }
      input {
        background-color: $color-white;
        padding-left: 0;
      }
    }
  }
  &__title {
    padding-right: 25px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: $gray-color-tertiary;
    position: absolute;
    top: 5px;
    left: 12px;
    z-index: 2;
    pointer-events: none;
    user-select: none;
    &.invalid {
      color: $accent-color-1;
    }
  }
  &.password {
    padding-right: 34px;
  }
  &__error-message {
    margin-top: 2px;
    font-size: 12px;
    color: $danger-color-1;
  }
  &__eye-icon {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
    color: $gray-color-secondary;
    svg {
      width: 20px;
      height: 20px;
      fill: $gray-color-secondary;
    }
  }
  &__number-icons {
    position: absolute;
    right: 10px;
    top: 50%;
    user-select: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: column;
    transform: translateY(-50%);
    svg {
      fill: $gray-color-secondary;
      height: 10px;
      width: 20px;
    }
  }
}
