@import '../../../assets/scss/variables.scss';

.form-textarea {
  height: 120px;
  width: 100%;
  background-color: $gray-color-6;
  padding: 15px 12px 5px;
  border-radius: 18px;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  font-size: 16px;
  &__container {
    border-radius: 18px;
    overflow: hidden;
    position: relative;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: pointer;
    }
  }
  &__title {
    position: absolute;
    top: 15px;
    left: 13px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: $gray-color-tertiary;
    z-index: 2;
    &.filled {
      font-size: 12px;
      line-height: 15px;
      top: 2px;
      left: 12px;
    }
  }
}
