@import '../../../assets/scss/variables.scss';

.form-color {
  &__input {
    height: 36px;
    padding: 8px 15px 8px 10px;
    display: flex;
    gap: 10px;
    background-color: $gray-color-6;
    border-radius: 18px;
    font-size: 16px;
    font-weight: 400;
    line-height: 14px;
    color: $gray-color-body;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &-image {
      width: 20px;
      height: 20px;
      border-radius: 2px;
    }
    &.empty {
      color: $gray-color-tertiary;
    }
  }
  &__container {
    position: relative;
    width: 50%;
  }
  &__modal {
    position: absolute;
    top: 40px;

    &-item > .flexbox-fix {
      div:first-child > div > input {
        text-align: center;
        width: 70px !important;
        position: relative;
      }
      div:not(:first-child) > div > input {
        text-align: center;
        width: 35px !important;
      }
    }
  }
}
