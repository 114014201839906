@import '../../assets/scss/variables.scss';

.user-settings--sidebar {
  width: 100%;
  height: max-content;
  border: 1px solid #e3e3e3;
  border-radius: 18px;
  padding: 12px;
  &-item {
    cursor: pointer;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: left;
    color: black;
    border-radius: 18px;
    padding: 10px 12px;
    gap: 8px;
    svg,
    img {
      width: 20px;
      height: 20px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    &.active {
      background-color: rgba(221, 0, 49, 0.03);
      svg,
      img {
        filter: invert(12%) sepia(95%) saturate(4173%) hue-rotate(338deg) brightness(96%) contrast(112%);
        color: $red;
        fill: $red;
      }
      p {
        color: $red;
      }
    }
  }
}

@media (max-width: $xs) {
  .user-settings--sidebar {
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
    &-item {
      width: 100%;
      flex-wrap: nowrap;
    }
  }
}
