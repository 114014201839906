@import '../../../assets/scss/variables.scss';

.button-link {
  color: $accent-color-1;
  display: flex;
  align-items: center;
  column-gap: 4px;
  cursor: pointer;

  .label {
    font-weight: 600;
    font-size: 16px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}
