@import '../../assets/scss/variables';

.header {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-height: 120px;
  border-bottom: 1px solid $border-gray-color;
  background-color: $color-white;
  padding: 12px 20px 12px 16px;
  &-icons-group {
    display: flex;
    gap: 20px;
    height: 44px;
    .header-icons-item {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      border-radius: 18px;
      transition: 0.5s;
      &:hover:not(.active) {
        background-color: $gray-color-6;
      }
      &:active {
        background-color: $gray-color-4;
      }
    }
  }
  &-main-group {
    display: flex;
    align-items: center;
    padding-top: 4px;
    max-width: 50%;
    gap: 7px 16px;
    flex-wrap: wrap;
    height: max-content;
    &__bars {
      color: $gray-color-body;
      width: 36px;
      height: 36px;
      border-radius: 18px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
      svg {
        width: 20px;
        height: 20px;
      }
      &:hover {
        background-color: $gray-color-6;
      }
      &:active {
        background-color: $gray-color-4;
      }
    }
    &__logo {
      height: 36px;
      width: 36px;
    }
  }
  &-global__container {
    position: relative;
    cursor: pointer;
    .global-option__container {
      height: 36px;
      padding: 8px;
      border-radius: 4px;
      width: 100%;
      transition: 0.5s;
      display: flex;
      align-items: center;
      &:hover {
        background-color: $gray-color-6;
      }
      &-text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 20px);
        div {
          width: 100%;
          overflow-y: hidden;
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: center;
          font-weight: 600;
          line-height: 1;
        }
      }
      &-icon {
        margin: auto;
        svg {
          width: 20px;
          height: 20px;
        }
      }

      &__ul {
        position: absolute;
        top: 40px;
        left: 0px;
        box-shadow: $shadow-dropdown-menu;
        border: none;
        padding: 4px;
        border-radius: 4px;
        background-color: $color-white;
        z-index: 100;
        .global-option__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .option--item-label {
          overflow-y: hidden;
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: $gray-color-body;
        }
        .option--item-number {
          width: max-content;
          max-width: 50%;
          overflow-y: hidden;
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: $gray-color-secondary;
        }
      }
    }

    svg {
      height: 20px;
    }
  }

  .navbar--option-li {
    cursor: pointer;
    opacity: 1;
    background-color: $color-white;
    border-radius: 4px;
    height: 36px;
    width: 242px;
    padding: 9px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
    font-family: 'Roboto';

    &:hover {
      background-color: $gray-color-6;
    }
    &:active {
      background-color: $gray-color-5;
    }
  }
}

@media (max-width: $xs) {
  .header {
    align-items: flex-start;
    &-icons-group {
      gap: 8px;
    }
    &-global__container {
      width: calc(100vw - 40px);
      .global-option__container {
        width: calc(100vw - 40px);
        &-text {
          width: max-content;
          padding-left: 5px;
        }
        &-icon {
          margin: auto 0 auto 5px;
        }
        &__ul {
          .navbar--option-li {
            width: 342px;
          }
        }
      }
    }
  }
}
