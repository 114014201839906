@import '../../../assets/scss/variables';

.menu-search__container {
  box-sizing: border-box;
  height: 36px;
  padding: 2px 4px 2px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
  border-radius: $border-radius-primary;
  background-color: $gray-color-6;
  color: $gray-color-tertiary;

  &.focused {
    padding: 1px 4px 1px 8px;
    border: 1px solid $accent-color-1;
    background-color: transparent;
    color: $accent-color-1;
  }

  &.closed {
    min-width: 36px;
    padding: 4px;
    color: $gray-color-tertiary;
    background-color: transparent;
    border: none;

    .menu-search__input {
      display: none;
    }

    svg {
      width: 28px;
      height: 28px;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }

  .menu-search {
    &__input {
      width: 116px;
      height: 18px;
      flex-grow: 1;
      font-size: 14px;
      color: $gray-color-body;
      caret-color: $accent-color-1;
    }

    &__close-btn {
      display: block;
      width: 32px;
      height: 32px;
      padding: 6px;
      color: $gray-color-tertiary;
      border-radius: 16px;
      &:hover {
        color: $gray-color-secondary;
        background-color: $gray-color-6;
      }
      &:active {
        color: $gray-color-2;
        background-color: $gray-color-5;
      }
      &.hidden {
        display: none;
      }
    }
  }
}

@media (max-width: $xs) {
  .menu-search {
    &__input {
      font-size: 16px;
    }
  }
}
