@import '../../assets/scss/variables.scss';

.labeled-value {
  .value {
    margin-top: 4px;
    line-height: 1.25;
  }

  .label {
    color: $gray-color-secondary;
    font-size: 12px;
    line-height: 1.25;
  }
}
