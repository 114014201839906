@import '../../scss/variables';

.queue-table {
  position: absolute;
  left: 0;
  width: calc(600% + 5px);
  display: flex;
  height: 100%;
  align-items: center;

  &.mini {
    width: calc(300% + 2px);
  }

  &__header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-size: 20px;
    font-weight: 800;
    color: $gray-color-2;
  }
  &__switch-container {
    width: auto !important;
  }
  &__switch {
    position: static;
    top: 0;
    left: 0;
    transform: none;
    margin-left: auto;
  }
  &__body-cell {
    align-content: center;
  }
  &__tbody {
    &-row {
      border-bottom: none;
    }
  }
  &__header-cell {
    &:last-child {
      padding-right: 16px;
    }
  }
  &__container {
    position: relative;
  }
  &__loading {
    margin: 8px 10px;
    height: 20px;
    animation-duration: 3s;
    animation-name: var(--animationNames);
    animation-iteration-count: infinite;
    animation-direction: normal, reverse;
    color: $color-white;
    padding: 0 5px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    overflow-x: hidden;

    &.cancel {
      width: calc(100% - 8px);
    }
  }

  &__delay-indicatior {
    position: absolute;
    right: 12px;
    top: 50%;
    height: 14px;
    margin-left: auto;
    padding: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    border-radius: 8px;
    background-color: $accent-color-3;
    color: $color-white;
    transform: translateY(-50%);
  }

  &__tbody-row.cancel {
    animation-name: cancelAnimation;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-duration: 0.8s;
  }

  &__tbody-row.activated {
    animation-name: activatedAnimation;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-duration: 0.8s;
  }

  &__tbody-row.delayed {
    animation-name: delayedAnimation;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-duration: 0.8s;
  }

  &__tbody-row.completed {
    animation-name: completedAnimation;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-duration: 0.8s;
  }

  &__status {
    display: flex;
    gap: 8px;
    width: max-content;
    &-color {
      margin-top: 4px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }

    &-catering {
      background-color: $accent-color-1;
      color: $color-white;
      padding: 1px 5px 0;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 700;
      height: 16px;
    }

    &-description {
      background-color: $gray-color-tertiary;
      color: $color-white;
      padding: 1px 5px 0;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 700;
      height: 16px;
    }

    &-stockout {
      background-color: $orange-2;
      color: $color-white;
      padding: 1px 5px 0;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 700;
      height: 16px;
    }
  }

  &__estimate-cell {
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &__tag {
      padding: 1px 8px;
      border-radius: 20px;
      color: $green;
      background-color: rgba($green, 0.1);
      vertical-align: middle;
      font-size: 12px;
      font-weight: 500;
      line-height: 1;

      &.completed {
        background-color: transparent;
        color: $color-white;
      }

      &.animated {
        animation-name: estimateTagAnimation;
        animation-direction: normal, reverse;
        animation-iteration-count: infinite;
        animation-duration: 3s;
      }
    }
  }
}
@keyframes changeWidth {
  50% {
    width: var(--statusScale);
  }
}
@keyframes changeBorder {
  0% {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  30% {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

@keyframes estimateTagAnimation {
  0% {
    color: $green;
    background-color: rgba($green, 0.1);
  }

  50% {
    background-color: transparent;
    color: $color-white;
  }
}

@keyframes cancelAnimation {
  from {
    background-color: unset;
  }

  to {
    background-color: rgba(255, 0, 0, 0.1);
  }
}

@keyframes activatedAnimation {
  from {
    background-color: unset;
  }

  to {
    background-color: rgba($warning-color-1, 0.2);
  }
}

@keyframes delayedAnimation {
  from {
    background-color: unset;
  }

  to {
    background-color: rgba($warning-color-1, 0.2);
  }
}

@keyframes completedAnimation {
  from {
    background-color: unset;
  }

  to {
    background-color:  rgba($success-color-1, 0.2);
  }
}
