@import '../../assets/scss/variables';

.main-sidebar__static-wrapper {
  position: relative;
  height: 100%;
  width: 69px;
  min-width: 69px;
  border-right: 1px solid $border-gray-color;
  &.opened {
    .main-sidebar {
      &__container {
        position: absolute;
        width: 250px;
        box-shadow: $shadow-main-sidebar;
        z-index: 100;
      }

      &__item {
        &-label {
          display: inline-block;
        }
      }

      &__nav {
        padding-bottom: 12px;
        border-bottom: 1px solid $border-gray-color;
      }
    }
  }

  .main-sidebar {
    &__container {
      position: relative;
      height: 100%;
      width: 68px;
      &-scrollable {
        height: 100%;
        width: 100%;
        padding: 20px 16px 16px 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        row-gap: 12px;
        background-color: white;
        overflow-y: auto;
      }
    }

    &__search {
      height: 36px;
      padding: 4px;
      svg {
        width: 28px;
        height: 28px;
      }
    }

    &__nav-list {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
    }

    &__item {
      position: relative;
      height: 36px;
      min-width: 36px;
      padding: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 10px;
      color: $gray-color-1;
      text-decoration: none;
      line-height: 1;
      border-radius: $border-radius-primary;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      user-select: none;
      &:hover {
        background-color: $gray-color-6;
      }
      &.active {
        color: $accent-color-1;
        background-color: $accent-color-7;
      }
      &.disabled {
        opacity: 0.5;
        cursor: default;
        &:hover {
          background-color: transparent;
        }
      }
      svg {
        height: 20px;
        width: 20px;
      }
      &-label {
        display: none;
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      &__email {
        display: flex;
        flex-direction: column;
        row-gap: 2px;
        color: $gray-color-1;
        text-decoration: underline;
        &:hover {
          color: $gray-color-1;
        }

        svg {
          width: 18px;
          height: 18px;
          color: $accent-color-1;
        }
      }

      &__copyright {
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: $gray-color-tertiary;
      }
    }

    &__subitems-menu {
      position: absolute;
      padding: 4px;
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      border-radius: 4px;
      background-color: white;
      box-shadow: $shadow-dropdown-menu;
      z-index: 200;

      &__item {
        padding: 8px 12px 8px 8px;
        border-radius: 4px;
        color: $gray-color-1;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          background-color: $gray-color-6;
        }
      }
    }
  }
}

@media (max-width: $xs) {
  .main-sidebar__static-wrapper {
    width: 0;
    min-width: 0px;
    .main-sidebar {
      &__container {
        width: 0px;
        &-scrollable {
          padding: 0;
        }
      }
    }

    &.opened {
      .main-sidebar {
        &__container {
          width: 100vw;
          &-scrollable {
            padding: 20px 16px 16px 16px;
          }
        }
        &__subitems-menu {
          display: none;
        }
        &__nav {
          height: max-content;
          flex-grow: 1;
        }
      }
    }
  }
}
