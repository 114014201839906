@import '../../assets/scss/variables';

.side-item-details__container {
  min-width: 390px;
  flex: 0 0 390px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $border-gray-color;
  background-color: white;
  overflow: hidden;

  .side-item-details {
    &__header {
      width: 100%;
      padding: 24px 16px 24px 28px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__tools {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 12px;
    }

    &__main {
      flex: 1 1 auto;
      padding: 0 28px 24px 28px;
      overflow: auto;
    }

    &__title {
      font-size: 28px;
      font-weight: 800;
      color: $gray-color-body;
    }

    &__content {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      row-gap: 24px;
    }
  }

  @media (max-width: $md) {
    position: absolute;
    right: 0;
    z-index: 50;
  }

  @media (max-width: $xs) {
    min-width: auto;
    width: 100%;
    //position: absolute;
    //right: 0;
    //z-index: 50;
  }
}
