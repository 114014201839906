.resizable-window-content{
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
}

.corner-dot{
  display: block;
  width: 5px;
  height: 5px;
  position: absolute;
  z-index: 50;
}

.corner-dot-left-top{
  top:0;
  left:0;
}

.corner-dot-left-bottom{
  bottom: 0;
  left:0;
}

.corner-dot-right-top{
  top:0;
  right:0;
}

.corner-dot-right-bottom{
  bottom:0;
  right:0;
}

.left-line{
  top:5px;
  left: 0;
}

.right-line{
  top:2px;
  right:0;
}

.top-line{
  top:0;
  left:5px;
}

.bottom-line{
  bottom:0;
  left:5px;
}

.horizontal-line{
  display: block;
  height: 5px;
  position: absolute;
  cursor: ns-resize;
  z-index:50;
}

.vertical-line{
  display: block;
  width: 5px;
  position: absolute;
  cursor: ew-resize;
  z-index: 50;
}

.left-top-right-bottom-corner-dot{
  cursor: nwse-resize;
}

.left-bottom-right-top-corner-dot {
  cursor: nesw-resize;
}

.resizable-block{
  position: fixed;
}
