@import '../../assets/scss/variables.scss';

.monitoring-graphic {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 18px;
  border: 1px $border-gray-color solid;
  gap: 16px;
  &.mini-graphic {
    width: 50%;
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__without-border {
    border: none;
    border-radius: 0;
    padding: 0;
  }
  div > div > div > div > div:nth-child(1) > div > svg > g > g > g > text {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    fill: $gray-color-2;
  }
  &__title {
    font-size: 14px;
    font-weight: 800;
    line-height: 18px;
    color: $gray-color-2;
    text-align: center;
  }
  &__item-chart {
    display: flex;
    gap: 28px;
    align-items: center;
    &__chart {
      width: calc(100% - 200px);
    }
  }
  &-network__values {
    width: 200px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    &-item {
      display: flex;
      flex-direction: column;
      gap: 4px;
      height: max-content;
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        color: $gray-color-secondary;
      }
    }
  }
}

.graphic-no-data--info {
  position: relative;
  overflow: hidden;
  width: calc(100% - 5px);
  margin-right: 5px;
  height: 190px;
  margin-top: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
  &--without-border {
    border: none;
  }
}
.server--error-icon {
  position: absolute;
  top: -30px;
  left: 0;
  padding: 20px 40px 10px 10px;
  background-color: #dd0030;
  color: #fff;
  font-size: 16px;
  transform: skewY(-45deg);
}
.system-table {
  width: calc(100% - 5px);
  margin-right: 5px;
  border-color: #e6e6ed;
  border-bottom: 1px solid #e6e6ed;

  th {
    background-color: #f6f6f8;
    border-top: 1px solid #e6e6ed;
    border-bottom: 1px solid #e6e6ed;
    font-weight: bold;
    z-index: 1;
    white-space: nowrap;
  }
  tbody tr {
    &:nth-child(2n) {
      background-color: #f6f6f8;
    }
    td {
      padding: 0.2rem;
      display: table-cell;
      max-width: calc(100% + 1.4rem);
    }
  }
  th,
  td {
    font-size: 14px;
    padding: 5px;
    transition: background-color 0.3s;
    color: #212529;
    line-height: 21px;
    vertical-align: top;
    white-space: pre-wrap;
    &.align-right {
      text-align: right;
    }
  }
}
.custom-gauge {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 120px;
  width: 120px;
  position: relative;
  &__canvas {
    direction: ltr;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 120px;
    height: 120px;
  }
  &__span {
    width: 100%;
    height: 100%;
    position: absolute;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;

    &.success {
      color: $success-color-1;
    }
    &.warning {
      color: $warning-color-1;
    }
    &.danger {
      color: $danger-color-1;
    }
  }
  &__label {
    width: 100%;
    height: 100%;
    padding: 0 40px;
    position: absolute;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: $gray-color-secondary;
  }
}

@media (max-width: $xs) {
  .monitoring-graphic {
    &.mini-graphic {
      width: 100%;
    }
    &__item-chart {
      flex-direction: column;
      &__chart,
      &__values {
        width: 100%;
      }
    }
  }
}
