@import '../../assets/scss/variables';

.datetime-input__container {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 44px;
  background-color: $gray-color-6;
  border-radius: $border-radius-primary;
  &:hover {
    background-color: $gray-color-5;
  }
  &.active {
    background-color: $gray-color-4;
    &:hover {
      background-color: $gray-color-4;
    }
  }
  &.error {
    .datetime-input__input {
      padding-right: 60px;
    }
  }
  &.not-empty {
    .datetime-input__input {
      padding-top: 16px;
      .datetime-input__field-label {
        display: block;
      }
    }
  }

  .datetime-input__dropdown-panel {
    position: absolute;
    padding: 10px;
    top: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(black, 0.1);
    background-color: #fff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    z-index: 10;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }

    .time-control {
      padding: 20px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 5px;
    }

    .navigation-tabs {
      width: 100%;
      height: 40px;
      display: flex;

      &__item {
        height: 100%;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(black, 0.2);
        cursor: pointer;
        svg {
          height: 18px;
          width: 18px;
        }
        &.active {
          color: $accent-color-1;
          border-bottom: 2px solid $accent-color-1;
        }
      }
    }
    .rdrCalendarWrapper {
      .rdrDayToday > span > span {
        color: $accent-color-1;
        &:after {
          display: none;
        }
      }
      .rdrDayDisabled {
        background-color: white;
        cursor: default;
        .rdrDayNumber span {
          color: $gray-color-4 !important;
        }
      }
      .rdrSelected {
        left: 5px;
        top: 0;
        width: 33px;
        height: 33px;
        overflow: visible;
        color: white !important;
        & ~ .rdrDayStartPreview,
        & ~ .rdrDayEndPreview {
          display: none;
        }
        & ~ .rdrDayNumber {
          width: 33px;
          height: 33px;
          overflow: visible;
          border: none;
          border-radius: 16.5px;
          background-color: $accent-color-1 !important;
          top: 1px;
          left: 5px !important;
          & > span {
            color: white !important;
            line-height: 33px;
            font-weight: 400;
          }
          &::after {
            content: none;
          }
        }
      }
      .rdrDayHovered {
        color: white !important;
      }
      .rdrDayStartPreview {
        z-index: 0;
        top: 0;
        color: white !important;
        left: 5px;
        top: 0;
        width: 33px;
        height: 33px;
        & ~ .rdrDayNumber {
          width: 33px;
          height: 33px;
          overflow: visible;
          border-radius: 16.5px;
          background-color: $accent-color-8;
          border: 1px solid $accent-color-7;
          top: 1px;
          left: 5.5px;
        }
      }
      .rdrDayPassive {
        .rdrSelected ~ .rdrDayNumber {
          background-color: $accent-color-7 !important;
        }
      }
    }
  }
  .datetime-input__input {
    height: 100%;
    padding: 0 35px 0 12px;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    cursor: default;

    .datetime-input__field-label {
      display: none;
      position: absolute;
      top: 5px;
      left: 12px;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.25;
      color: $gray-color-secondary;
    }

    .datetime-input__icon {
      position: absolute;
      top: 50%;
      right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      font-size: 14px;
      height: 24px;
      cursor: default;
      transform: translateY(-50%);
      color: $gray-color-secondary;
      &:hover {
        color: $gray-color-body;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }

    .datetime-input__error {
      position: absolute;
      top: 50%;
      right: 35px;
      margin-right: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: $accent-color-1;
      cursor: default;
      transform: translateY(-50%);
      svg {
        width: 20px;
        height: 20px;
      }
    }

    .datetime-input__label {
      &-date {
        margin-right: 5px;
        font-size: 16px;
      }
      &-time {
        padding: 5px;
        border-radius: 6px;
        &:hover {
          background-color: rgba(black, 0.05);
        }
      }
    }

    input {
      height: 18px;
      font-size: 14px;
      color: $gray-color-body;
      max-width: 420px;
      width: 100%;
    }
    span {
      white-space: nowrap;
    }
    input.invalid {
      color: #dd0030;
    }
  }
}
