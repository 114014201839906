@import "../../assets/scss/variables";

.time-input__container {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 44px;
  background-color: $gray-color-6;
  border-radius: $border-radius-primary;
  &:hover {
    background-color: $gray-color-5;
  }
  &.active {
    background-color: $gray-color-4;
    &:hover {
      background-color: $gray-color-4;
    }
  }
  &.error {
    .time-input__input {
      padding-right: 60px;
    }
  }
  &.not-empty {
    .time-input__input {
      padding-top: 16px;
      .time-input__field-label {
        display: block;
      }
    }
  }

  .time-input__dropdown-panel {
    position: absolute;
    padding: 10px;
    top: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(black, 0.1);
    background-color: #fff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    z-index: 10;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }

    .time-control {
      padding: 10px;
    }
  }
  .time-input__input {
    height: 100%;
    padding: 0 35px 0 12px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    cursor: default;

    .time-input__field-label {
      display: none;
      position: absolute;
      top: 5px;
      left: 12px;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.25;
      color: $gray-color-secondary;
    }

    .time-input__icon {
      position: absolute;
      top: 50%;
      right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      font-size: 14px;
      height: 24px;
      transform: translateY(-50%);
      color: $gray-color-secondary;
      &:hover {
        color: $gray-color-body;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }

    .time-input__error {
      position: absolute;
      top: 50%;
      right: 35px;
      margin-right: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: $accent-color-1;
      cursor: default;
      transform: translateY(-50%);
      svg {
        width: 20px;
        height: 20px;
      }
    }

    input {
      padding: 0;
      height: 18px;
      font-size: 14px;
      color: $gray-color-body;
      max-width: 420px;
      width: 100%;
    }
    input.invalid {
      color: #dd0030;
    }
  }
}

