@import '../../../assets/scss/variables';

.dashboard-graphics__container {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .dashboard-chart {
    width: 100%;

    &__group-title {
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: 800;
      color: $gray-color-2;
    }

    &__chart-title {
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: 800;
      color: $gray-color-2;
    }

    &__info-block {
      width: 100%;
      height: 162px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    text {
      font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 12px;
    }
  }
}

@media (max-width: $xs) {
  .dashboard-chart {
    svg {
      overflow: initial !important;
    }
    & > div > div > div > div:nth-child(1) > div > svg > g > g:nth-child(4):not(:has(> rect)) {
      height: 20px;
      transform: translateY(10px);
      display: block;
    }
  }
}
