@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(#fff, 0.6);

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
    border: 4px solid #aa0025;
    border-color: #aa0025 transparent #aa0025 transparent;
  }
}
.big--spinner {
  width: 30px;
  height: 30px;
}
