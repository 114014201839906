.catering-block {
  width: 100%;
  overflow-x: auto;
  height: 100%;
  .infinite-scroll-component {
    display: flex;
  }
  &__container {
    display: flex;
    width: max-content;
    gap: 16px;
  }
  &__loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__scroll-loader {
    width: max-content;
    display: flex;
    align-items: center;
  }
  &__item {
    width: 325px;
    padding-bottom: 8px;
    border-radius: 18px;
    border: 1px solid $border-gray-color;
    height: max-content;
  }
  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1px 16px;
    &-container {
      padding: 5px 0 3px;
    }
    &-item {
      display: flex;
      color: $gray-color-tertiary;
      font-size: 12px;
      font-weight: 700;
      align-items: center;
    }
    &-right {
      display: flex;
      width: max-content;
      gap: 12px;
      align-items: center;
    }
    &-date {
      display: flex;
      align-items: center;
      gap: 4px;
      line-height: 1;
      min-width: 65px;
      svg {
        display: block;
        width: 20px;
        height: 20px;
        color: $gray-color-tertiary;
      }
    }
  }
  &__body-item {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $border-gray-color;
    padding: 12px 16px;
    gap: 12px;
    &__header {
      display: flex;
      justify-content: space-between;
      &-tags {
        display: flex;
        column-gap: 4px;
      }
      span {
        font-weight: 800;
      }
      .catering-status {
        padding: 1px 5px 0;
        border-radius: 9px;
        color: $color-white;
        font-size: 12px;
        font-weight: 700;
        background-color: $gray-color-3;
        &.ready {
          background-color: $success-color-1;
        }
        &.incomplete {
          background-color: $accent-color-1;
        }
        &.canceled {
          background-color: $accent-color-1;
        }
        &.go {
          background-color: $warning-color-1;
        }
        &.waiting {
          background-color: $info-color-1;
        }
        &.delayed {
          background-color: $orange;
        }
      }
    }
    &__time {
      display: flex;
      align-items: center;
      gap: 4px;
      svg {
        display: block;
        width: 20px;
        height: 20px;
        color: $gray-color-tertiary;
      }
    }
    &__info {
      display: flex;
      gap: 16px;
      .catering-info__container {
        span {
          font-size: 12px;
          color: $gray-color-tertiary;
          margin-bottom: 4px;
        }
      }
    }
  }
}
