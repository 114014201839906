@import './desktopTable.scss';
@import './mobileTable.scss';
@import './recordingsTable.scss';
@import './unusualTable.scss';
@import './queueTable.scss';
@import './cateringBlock.scss';
@import './hierarchicalTable.scss';

.info-cell {
  padding: 9px 0;
  text-align: center;
  .no-info {
    opacity: 0.5;
  }
}
