@import '../../../assets/scss/variables.scss';

.performances__info-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  overflow-y: auto;

  .performances__chart-part {
    min-width: 356px;
    margin-top: 16px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .pie-chart__wrapper {
      position: relative;
      user-select: none;
    }
    .pie-chart__description {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 12px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      .pie-chart__item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        color: $gray-color-2;
        .color__indicator {
          width: 6px;
          height: 6px;
          border-radius: 3px;
          &.green-zone {
            background-color: $success-color-3;
          }
          &.light-green-zone {
            background-color: $success-color-1;
          }
          &.lighter-green-zone {
            background-color: $success-color-2;
          }
          &.yellow-zone {
            background-color: $warning-color-1;
          }
          &.orange-zone {
            background-color: #ff7d00;
          }
          &.red-zone {
            background-color: $accent-color-1;
          }
        }
      }
    }
    .google-visualization-tooltip {
      pointer-events: none;
    }
  }
  .performances__table-part {
    width: 80%;
    height: max-content;
  }
}

@media (max-width: $xs) {
  .performances__info-container {
    flex-direction: column;
    justify-content: normal;
    align-items: center;
    .performances__chart-part {
      min-width: unset;
    }
    .performances__table-part {
      width: 100%;
    }
  }
}
