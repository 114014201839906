@import '../../../assets/scss/variables';

.form-multi-select-filter {
  position: relative;
  min-height: 44px;
  display: flex;
  flex-direction: column;
  &.opened {
    .filter__toggle {
      background-color: $gray-color-4;
      &:hover {
        background-color: $gray-color-4;
      }
      .filter__label-container {
        .filter__values__item {
          background-color: $gray-color-3;
        }
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    .filter__toggle {
      &:hover {
        background-color: $gray-color-6;
      }
    }
  }

  .filter__toggle {
    flex: 1 1 100%;
    padding: 5px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 12px;
    border-radius: $border-radius-primary;
    background-color: $gray-color-6;
    line-height: 1;
    cursor: default;
    &:hover {
      background-color: $gray-color-5;
    }
    svg {
      width: 20px;
      height: 20px;
    }
    .filter__label-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .filter__label {
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        white-space: nowrap;
        color: $gray-color-body;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
      }
      .filter__title {
        color: $gray-color-tertiary;
        font-weight: 400;
        font-size: 14px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        &.selected {
          font-size: 12px;
          line-height: 15px;
          pointer-events: none;
          user-select: none;
        }
      }
      .filter__values {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 4px;
        row-gap: 4px;
        &__item {
          padding: 3px 4px 3px 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          column-gap: 4px;
          border-radius: 11px;
          background-color: $gray-color-4;
        }
      }
    }
    .filter__controls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 4px;
    }
    .filter__cancel-btn {
      padding: 0;
      color: $gray-color-tertiary;
      &:hover {
        color: $gray-color-secondary;
      }
      &:active {
        color: $gray-color-2;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .filter__dropdown-menu {
    position: absolute;
    top: calc(100% + 2px);
    border-radius: $border-radius-dropdown-list;
    background-color: $second-background-color;
    box-shadow: $shadow-context-menu;
    z-index: 10;

    &__search {
      height: 36px;
      padding: 8px 4px 8px 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 8px;
      border-bottom: 1px solid $border-gray-color;
      svg {
        width: 20px;
        height: 20px;
        opacity: 0.5;
      }
      input {
        height: 18px;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &__options {
      max-height: 300px;
      padding: 4px;
      overflow: auto;
      .option {
        padding: 9px 12px 9px 12px;
        white-space: nowrap;
        border-radius: 4px;
        cursor: default;
        &:hover {
          background-color: $gray-color-6;
        }
        &.active {
          background-color: $gray-color-5;
        }
      }
    }
  }
}
