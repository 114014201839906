@import '../../../assets/scss/variables.scss';
.form-radio-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .form__radio {
    display: flex;
    gap: 8px;
    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      & + label::before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 1px solid #adb5bd;
        border-radius: 50%;
        margin-right: 8px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
      }
      &:checked + label::before {
        border-color: $accent-color-1;
        background-color: $accent-color-1;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
      }
    }
    label {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      color: $gray-color-body;
      margin: 0;
      display: flex;
      align-items: center;
    }

    &-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &-description {
      padding-left: 26px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: $gray-color-secondary;
    }
  }
}
.form-radio__slider {
  &__container {
    display: flex;
    gap: 12px;
    padding: 7px 0;
    &.disabled {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }
  }
  &__span {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: $gray-color-body;
  }

  &__input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  &__label {
    cursor: pointer;
    width: 30px;
    height: 10px;
    background: $gray-color-3;
    display: block;
    border-radius: 8px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: -3px;
      left: 0px;
      width: 16px;
      height: 16px;
      background: $second-background-color;
      box-shadow: $shadow-radio-input;
      border-radius: 13px;
      transition: 0.3s;
    }
  }

  &__input:checked + .form-radio__slider__label {
    background: $accent-color-9;
  }

  &__input:checked + .form-radio__slider__label:after {
    left: 14px;
    background: $accent-color-1;
  }
}
