@import '../../../assets/scss/variables';

.sort-filter {
  position: relative;
  height: 36px;
  &.opened {
    .filter__toggle {
      background-color: $gray-color-4;
      &:hover {
        background-color: $gray-color-4;
      }
    }
  }

  .filter__toggle {
    height: 100%;
    padding: 8px 10px 8px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: $border-radius-primary;
    background-color: $gray-color-6;
    line-height: 1;
    cursor: default;
    &:hover {
      background-color: $gray-color-5;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    .filter__label {
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      color: $gray-color-body;
    }

    .filter__direction {
      width: 22px;
      height: 22px;
      margin-left: 8px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 2px solid white;
      background-color: $gray-color-6;
      color: $gray-color-body;
      svg {
        width: 16px;
        height: 16px;
      }
      &:hover {
        background-color: $gray-color-5;
      }
    }
  }

  .filter__dropdown-menu {
    position: absolute;
    top: 40px;
    border-radius: $border-radius-dropdown-list;
    background-color: white;
    box-shadow: $shadow-context-menu;
    z-index: 10;

    &__options {
      max-height: 300px;
      padding: 4px;
      overflow: auto;
      .option {
        padding: 9px 12px 9px 12px;
        white-space: nowrap;
        border-radius: 4px;
        cursor: default;
        &:hover {
          background-color: $gray-color-6;
        }
        &.active {
          background-color: $gray-color-5;
        }
      }
    }
  }
}
