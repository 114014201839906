@import '../variables.scss';

.recordings-table {
  display: flex;
  flex-direction: column;
  gap: 12px;
  &__container {
    width: 100%;
    min-height: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    i {
      width: max-content;
    }
    .no-info {
      opacity: 0.5;
    }
    &.inner-scroll {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  &__wrapper {
    width: 100%;
    .cameras-recordings__header-cell {
      padding: 0 8px 8px;
    }
  }
  &__item {
    border-radius: 18px;
    border: 1px solid $border-gray-color;
  }
  &__row {
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    .recordings-table__status {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 8px;
      word-wrap: break-word;
      word-break: break-all;
      .status-cell {
        justify-content: flex-start !important;
        align-items: flex-start !important;
      }
    }
  }
  &__extended-info {
    display: flex;
    width: 100%;
    gap: 12px;
    flex-wrap: wrap;
    padding: 0 16px 16px 50px;
    flex-direction: column;
    &__item {
      display: flex;
      flex-direction: row;
      gap: 4px;
      width: 100%;
    }
    &__header {
      width: 25%;
      color: $gray-color-secondary;
      font-size: 12px;
      word-wrap: break-word;
    }
    &__label {
      width: 75%;
      word-wrap: break-word;
      word-break: normal;
    }
  }
  &__cells {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
  }
  &__chevron {
    color: $gray-color-tertiary;
    padding: 0;
    svg {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
}
