@import '../../../assets/scss/variables';

.button-on-video {
  padding: 6px;
  border-radius: 70px;
  color: $gray-color-1;
  background-color: $color-white;
  &:not(:disabled):hover {
    color: $color-black;
    background-color: $gray-color-5;
  }
  &:not(:disabled):active,
  &.active {
    color: $accent-color-1;
    &:hover {
      color: $accent-color-1;
      background-color: $color-white;
    }
  }
  &:disabled {
    opacity: 0.5;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
