@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.manager-player__controls {
  @include position(absolute, 0 0 0 0);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  z-index: 1;
  &:hover {
    .manager-player__controls__header-button,
    .manager-player__controls__footer {
      visibility: visible;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__footer {
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
    overflow-x: auto;
    transition: visibility 0.3s ease-in-out;
  }

  &__title {
    height: 30px;
    padding: 0 6px;
    flex-shrink: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: $color-white;
    background-color: $player-title-background;
    border-radius: 4px;
    border: 1px solid $color-white;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__header-button {
    visibility: hidden;
    transition: visibility 0.3s ease-in-out;
  }
}

@media (max-width: $xs) {
  .modal {
    .manager-player__controls {
      &__header-button,
      &__footer {
        visibility: visible;
      }
    }
  }
}
