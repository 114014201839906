@import '../../assets/scss/variables';

.table-pagination {
  position: relative;

  &__container {
    padding: 16px 24px 16px 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $border-gray-color;

    .pagination {
      &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 8px;
      }

      &__pages {
        display: flex;
        list-style: none;
      }

      &__item {
        height: 36px;
        min-width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 18px;
        font-size: 14px;
        font-weight: 700;
        line-height: 1;
        cursor: default;
        &:hover {
          background-color: $gray-color-6;
        }
        &.active {
          background-color: $accent-color-5;
          color: $accent-color-1;
        }
        &.dots {
          &:hover,
          &.active {
            background-color: transparent;
          }
        }
      }

      &__control-btn {
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 18px;
        &:disabled {
          opacity: 0.5;
        }
        &:active {
          background-color: $gray-color-4;
        }
        &:hover {
          background-color: $gray-color-6;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

@media (max-width: $xs) {
  .table-pagination {
    width: 100%;
    height: max-content;
    position: relative;
    &__container {
      padding: 16px 5px;
      max-width: 100%;
      overflow-x: auto;
    }
  }
}
