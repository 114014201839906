@import '../../assets/scss/variables';

.local-time-widget {
  height: 36px;
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
  border: 1px solid $border-gray-color;
  border-radius: $border-radius-primary;

  &__label {
    font-size: 12px;
    font-weight: 800;
    line-height: 1;
    color: $gray-color-2;
  }

  &__value {
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: $accent-color-1;
  }
}
