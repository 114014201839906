@import '../../../assets/scss/variables';

.permissions-list-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 28px;

  .permissions-item {
    &__actions {
      margin-top: 20px;
      display: flex;
      align-items: center;
      column-gap: 16px;
    }

    &__action {
      display: flex;
      align-items: center;
      column-gap: 6px;
      line-height: 1;

      svg {
        width: 20px;
        height: 20px;
        color: $success-color-1;
      }
    }
  }
}
