@import '../../../assets/scss/variables';

.multi-code {
  &__container {
    display: flex;
    gap: 12px;
    align-items: center;
    height: 48px;
    width: 100%;
    &-small {
      .multi-code__input {
        width: 46px;
        height: 44px;
      }
    }
  }
  &__input {
    height: 43px;
    width: min(48.33px, 16%);
    background-color: $gray-color-6;
    border-radius: 18px;
    color: $color-black;
    text-align: center;
    font-size: 1.5rem;
  }
}
