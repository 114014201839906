@import '../../assets/scss/variables.scss';

.breadcrumbs__container {
  display: flex;
  padding: 0 28px 0 0;
  gap: 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $gray-color-tertiary;
  align-items: center;
  margin-bottom: 16px;
  .previous {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  svg {
    width: 12px;
    height: 12px;
  }
}
