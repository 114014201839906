@import '../../assets/scss/variables';

.notification-content {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 40px 8px 36px;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 4px;
  }

  &__message {
    font-weight: 600;
    line-height: 17.5px;
    max-height: 100px;
    text-overflow: ellipsis;
    overflow: auto;
  }

  &__time {
    font-size: 13px;
    line-height: 15px;
    color: $gray-color-tertiary;
  }

  &__close-mark {
    position: absolute;
    right: 0;
    cursor: pointer;
    width: 15px;
    height: 15px;
    color: $gray-color-secondary;
    background: url('../../assets/img/icons/notifications/Close.svg');
    background-position: center;
    background-repeat: no-repeat;
    top: auto;
    right: 15px;
    &::after {
      content: none;
    }
  }

  &--success,
  &--danger,
  &--info,
  &--warning {
    border-left: none;
    position: relative;
    &::before {
      content: '';
      width: 18px;
      height: 18px;
      position: absolute;
      left: 12px;
      top: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);
    }
  }
  &--success {
    .notification-content__message {
      color: $success-color-1;
    }
    &::before {
      background-image: url('../../assets/img/icons/notifications/Success.svg');
    }
  }
  &--info {
    .notification-content__message {
      color: $info-color-1;
    }
    &::before {
      background-image: url('../../assets/img/icons/notifications/Info.svg');
    }
  }
  &--warning {
    .notification-content__message {
      color: $warning-color-1;
    }
    &::before {
      background-image: url('../../assets/img/icons/notifications/Warning.svg');
    }
  }
  &--danger {
    .notification-content__message {
      color: $danger-color-1;
    }
    &::before {
      background-image: url('../../assets/img/icons/notifications/Error.svg');
    }
  }
}
