@import '../../assets/scss/variables.scss';

.modal {
  display: block !important;
  background-color: rgba(0, 0, 0, 0.3);
  color: $color-black;
  cursor: default;
  z-index: 999;
  &__side-container {
    background-color: $color-transparent;
  }

  &__delete,
  &__pattern {
    margin: 0 auto !important;
    .modal-content {
      border-radius: 18px;
      border: none;
      box-shadow: $shadow-modal;
      &__date {
        border-radius: 0;
        border-top-left-radius: 18px !important;
        border-top-right-radius: 18px !important;
      }
    }
    &-down {
      position: absolute !important;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0;
    }
    &-sm {
      width: 390px !important;
    }
    &-md {
      width: 768px !important;
      max-width: 90vw !important;
    }
    &-lg {
      max-width: min(768px, 100vw) !important;
      .modal-button {
        width: 120px;
        &__container {
          justify-content: flex-end;
        }
      }
    }
    &-xl {
      max-width: min(800px, 100vw) !important;
    }
  }
  &__side {
    margin: 0 0 0 auto !important;
    height: 100vh;
    overflow-y: hidden;
    .modal-content {
      height: 100%;
      border: none;
      border-radius: 0;
      .modal-body {
        overflow-y: auto;
      }
    }
    box-shadow: $shadow-side-modal;
  }
  &__side,
  &__delete,
  &__pattern {
    z-index: 1000;
    .modal-header {
      align-items: flex-start;
      padding: 24px 16px 24px 28px;
      border-bottom: none !important;
    }
    .modal-title {
      line-height: 1.2;
      font-weight: 800;
      font-size: 28px;
      font-family: 'Roboto';
    }
    .modal-body {
      padding: 0 28px;
      .delete__value {
        font-weight: 500;
        word-break: break-all;
      }
      &__scrolled {
        height: calc(100% - 84px);
        padding: 0;
      }
      &.scrolled {
        height: 70vh;
        overflow-y: auto;
      }
      &.without-padding {
        padding: 0;
      }
    }
    .modal-footer {
      padding: 28px;
      border-top: none !important;
      & > * {
        margin: 0;
      }
    }
    &-description {
      color: $gray-color-secondary;
      font-size: 12px;
    }
  }
  &-close__button {
    height: 36px;
    width: 36px;
    padding: 6px;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      fill: $gray-color-body;
    }
  }
  &-button {
    width: 155px;
    height: 44px;
    border-radius: 18px;
    padding: 10px 24px;
    border: 1px solid $accent-color-1;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;

    &.medium {
      width: 100%;
      padding: 10px 20px;
      height: 36px;
    }

    &.cut {
      width: 120px;
    }
    &__container {
      gap: 24px;
      display: flex;
      align-items: center;
      width: max-content;
    }
    &__cancel {
      color: $accent-color-1;
      transition: 0.5s;
      &:hover {
        background-color: $accent-color-5;
      }
      &:active {
        background-color: $accent-color-4;
        color: $accent-color-3;
        border-color: $accent-color-3;
      }
    }
    &__confirm {
      background-color: $accent-color-1;
      color: white;
      transition: 0.5s;
      &.disabled {
        pointer-events: none;
        opacity: 0.5 !important;
      }
      &:hover {
        background-color: $accent-color-2;
      }
      &:active {
        background-color: $accent-color-3;
      }
      &-delete {
        background-color: $danger-color-1;
        &:hover {
          background-color: $danger-color-2;
        }
        &:active {
          background-color: $danger-color-3;
        }
      }
    }
  }
}

@media (max-width: $xs) {
  .modal {
    &__delete,
    &__pattern {
      margin: 0 auto !important;
      &-sm {
        max-width: 100%;
      }
    }
    &__side {
      width: 100%;
      height: 100%;
      &-sm {
        width: 100% !important;
      }
    }
    &-button {
      &__container {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        gap: 5px;
      }
    }
    .mobile-filters__modal {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      padding: 0 20px;
      gap: 4px;
      &-vertical {
        flex-direction: column;
        .single-select-filter {
          width: max-content;
        }
      }
    }
  }
}
