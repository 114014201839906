@import '../../../assets/scss/variables';

.table-cell__switchable-image {
  position: relative;
  width: min(100%, 800px);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: min(100%, 800px);
  min-height: 50px;

  .images-switcher {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 4px;

    &__item {
      width: 30px;
      height: 30px;
      padding: 6px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      cursor: pointer;
      color: $gray-color-body;
      background-color: $color-white;
      box-shadow: $shadow-dropdown-menu;
      &.active {
        color: $color-white;
        background-color: $accent-color-1;
      }
    }
  }
}
