@import '../../assets/scss/variables';

.download-progress-bar {
  display: flex;
  justify-content: flex-start;
  border-radius: 10px;
  background-color: $gray-color-5;
  overflow: hidden;
  .progress-bar__loader {
    height: 100%;
    background-color: $accent-color-1;
  }
}
