@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins';

.page-total {
  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    border-radius: 18px;
    padding: 28px 15px;
    border: 1px solid $border-gray-color;
  }

  &-label {
    color: $gray-color-2;
    line-height: 20px;
    font-weight: 800;
    margin-bottom: 10px;

    @media (max-width: $xs) {
      font-size: 15px;
    }
  }

  &-value {
    word-wrap: break-word;
    width: 100%;
    font-size: 36px;
    text-align: center;
    line-height: 45px;
    color: $accent-color-1;

    @media (max-width: $md) {
      font-size: 25px;
    }
  }
}
