@import '../../assets/scss/variables';

.custom-tag {
  position: relative;
  padding: 2px 8px;
  width: max-content;
  max-width: 100%;
  font-size: 14px;
  border-radius: 4px;
  background-color: $gray-color-6;
  overflow: hidden;
  text-overflow: ellipsis;
}
