@import '../../../assets/scss/variables.scss';

.small-bar__chart {
  width: 100%;
  &-error {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  .small-bar__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    svg {
      height: 30px;
      width: 30px;
    }
  }
}
