@import '../variables.scss';

.hierarchical-table {
  svg {
    height: 16px;
    width: 16px;
    color: $gray-color-tertiary;
  }

  .custom-table {
    &__tbody-row {
      cursor: pointer;
      &.without-border {
        border-bottom: none;
      }
    }
  }
}
