@import '../../../assets/scss/variables';

.devices-availability {
  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    row-gap: 16px;
  }

  &__info-block {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item {
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 16px;
    border: 1px solid $border-gray-color;
    border-radius: $border-radius-primary;

    &__information {
      flex: 0 0 130px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 8px;
      overflow: hidden;

      .device-name {
        width: 100%;
        font-size: 12px;
        overflow: hidden;
        word-wrap: break-word;
      }

      .device-total-availability {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  &__chart {
    max-width: calc(100% - 130px - 16px);
    height: 100px;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    rect {
      stroke: none;
    }

    text {
      font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 12px;
    }

    .google-visualization-tooltip {
      background-color: transparent;
      border: none;
      border-radius: 0;
      box-shadow: 0 2px 8px 0 rgba(black, 0.15);
    }

    .custom-timeline-tooltip {
      padding: 6px 8px;
      background-color: $color-white;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 600;
      .online {
        color: $green;
      }
      .offline {
        color: $accent-color-1;
      }
    }
  }
}

@media (max-width: $xs) {
  .devices-availability {
    &__item {
      flex-direction: column;
      &__information {
        flex: 1 1 auto;
      }
    }
    &__chart {
      width: 100%;
      max-width: 100%;
    }
  }
}
