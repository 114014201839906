@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.modal-dialog--manager {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-header {
    display: inline-flex;
  }
  .modal-content {
    background-color: rgba(0, 0, 0, 0);
    height: 100%;
  }
  .modal_body_history {
    padding: 0 !important;
  }
  .manager_camera-view__player {
    width: 100%;
  }
}
.manager-video--container {
  position: relative;
  border-radius: 8px;
  background-color: $gray-color-body;
  overflow: hidden;
  &:hover {
    .information {
      visibility: visible;
    }
  }
  .information {
    position: absolute;
    left: 16px;
    bottom: 16px;
    padding: 5px;
    color: white;
    background-color: rgba(black, 0.6);
    border-radius: 6px;
    visibility: hidden;
    transition: 0.3s;
    li {
      margin: 5px 0;
    }
  }
}
.manager_camera-view__player {
  position: relative;
  background-color: inherit;
  overflow: hidden;
  font-size: 12px;
  color: $color-white;
  height: 100%;
  flex: 1;

  video {
    height: fit-content;
    max-height: 100%;
    width: fit-content;
    max-width: 100%;
    cursor: pointer;
  }
  .vjs-modal-dialog .vjs-modal-dialog-content {
    padding: 0 !important;
  }
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: 100%;
  }
}
.manager_camera-view__player-video {
  video {
    height: fit-content;
  }
}

.manager_camera-view__player-video,
.manager_camera-view__player-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  @include position(absolute, 0 0 0 0);
  height: 100%;
}
.manager_camera-view__player-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  @include position(absolute, 0 0 0 0);
  color: white;
  fill: white;

  svg {
    width: 18px;
    height: 18px;
  }
}
.manager_camera-view__player-time {
  @include position(absolute, null 0 0 null);
  padding: 15px 20px;
  font-size: 16px;
  color: $color-white;
  z-index: 30;
}

.manager_camera-view__player-placeholder {
  background-color: $color-black;
}

.manager_camera-view__player-error {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg,
  span {
    color: $color-error;
  }

  svg {
    width: 36px;
    height: 26px;
    margin-bottom: 10px;
  }
}

.manager_camera-view__player-container {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1 1 100%;
  &.fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }
  .player {
    flex: 1;
    min-height: 0;
  }
}

.manager_camera-view__player-analytics {
  position: absolute;
  top: 23px;
  right: 23px;
  width: 247px;
  z-index: 40;
}

.manager_player_container {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.manager-modal {
  max-height: 100%;
  &.no-data {
    width: 100%;
    height: 100%;
    .manager-modal-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: $xs) {
  .camera-container.mobile .react-transform-wrapper:has(.modal) {
    background-color: black;
    .manager-modal.modal {
      .manager-video--container {
        position: inherit;
        .information {
          visibility: visible;
        }
      }
    }
  }
}
