@import '../../../assets/scss/variables';

.permissions-field-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 28px;

  .permissions-field {
    &__tools {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .permissions-item {
    &__actions {
      margin: 20px 0 0 26px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 12px;
    }
  }
}
