@import '../../../assets/scss/variables.scss';

.monitoring-static {
  display: flex;
  gap: 16px;
  width: 50%;
  &__item {
    width: 100%;
    padding: 28px 0;
    border-radius: 18px;
    border: 1px solid $border-gray-color;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &-value {
      font-size: 40px;
      font-weight: 400;
      line-height: 50px;
      color: $accent-color-1;
      text-align: center;
      &.no-info {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
}
