@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.pie-chart-switch {
  &__container {
    padding: 2px;
    overflow: auto;
    @include scrollbarSize(2px, 0);
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1px;
  }

  &__item {
    flex: 1 1 auto;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-radius: 18px;
    color: $gray-color-1;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    cursor: pointer;

    &:not(&.active):hover {
      color: $accent-color-1;
      background-color: $gray-color-5;
    }

    &.active {
      color: $color-white;
      background-color: $accent-color-1;
    }
  }
}
