@import '../../../assets/scss/variables.scss';

.mobile-block {
  border: 1px solid $border-gray-color;
  border-radius: 18px;
  padding: 8px 0;
  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
  }
  &__item {
    padding: 9px 16px;
    display: flex;
    &:not(:last-child) {
      border-bottom: 1px solid $border-gray-color;
    }
    &-title,
    &-label {
      width: 50%;
    }
    &-button {
      width: 100%;
      background-color: $accent-color-1;
      color: $color-white;
      padding: 10px 16px;
      border-radius: 18px;
      font-weight: 600;
      font-size: 16px;
    }
  }
  &__bold-text {
    font-weight: 600;
    word-wrap: break-word;
  }
}
