@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.mini-player {
  position: fixed;
  background-color: #8d001f;
  overflow: hidden;
  z-index: 500;
  &.moving {
    cursor: move;
  }

  &__container {
    width: 100%;
    height: calc(100% - 40px);
    background-color: black;
  }

  &__metadata {
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
  }

  &__title {
    max-width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.miniplayer-video--container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  &:hover {
    background: rgba(black, 0.4);
    .video-header {
      visibility: visible;
    }
  }
  .video-header {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    cursor: default;
    z-index: 1;
    visibility: hidden;
    &--tools {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 10px;
      .tool-btn {
        height: 30px;
        padding: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        color: white;
        &:disabled {
          background-color: transparent !important;
          svg {
            &:hover {
              transform: none;
            }
          }
        }
        &.link {
          svg {
            width: 22px;
            height: 22px;
          }
        }
        &.times {
          svg {
            width: 18px;
            height: 18px;
          }
        }
        &.active {
          svg {
            color: $red;
          }
        }
        svg {
          width: 20px;
          height: 20px;
          transition: 0.1s;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
.miniplayer_camera-view__player {
  position: relative;
  background-color: inherit;
  overflow: hidden;
  font-size: 12px;
  color: $color-white;
  height: 100%;
  flex: 1;

  video {
    height: fit-content;
    max-height: 100%;
    width: fit-content;
    max-width: 100%;
  }
  .vjs-modal-dialog .vjs-modal-dialog-content {
    padding: 0 !important;
  }
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: 100%;
  }
}
.miniplayer_camera-view__player-video {
  video {
    height: fit-content;
  }
}

.miniplayer_camera-view__player-video {
  display: flex;
  justify-content: center;
  align-items: center;
  @include position(absolute, 0 0 0 0);
  height: 100%;
}
.miniplayer_camera-view__player-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  @include position(absolute, 0 0 0 0);
  color: white;
  fill: white;

  svg {
    width: 18px;
    height: 18px;
  }
}

.miniplayer_camera-view__player-error {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg,
  span {
    color: $color-error;
  }

  svg {
    width: 36px;
    height: 26px;
    margin-bottom: 10px;
  }
}
