@import '../../assets/scss/variables';

.cameras-sidebar {
  flex-basis: 384px;
  flex-shrink: 0;
  flex-grow: 0;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid $border-gray-color;
  border-radius: $border-radius-primary;
  overflow: hidden;

  &__list {
    height: 100%;
    padding: 6px 0;
    overflow-y: auto;
    .camera-container.mobile {
      width: 100%;
      height: 210px;
      .react-transform-wrapper:has(.show) {
        position: absolute;
        top: 0;
        z-index: 100;
      }
      .manager-video--container {
        border-radius: $border-radius-primary;
        .information {
          bottom: 65px;
        }
      }
    }
    &-item {
      position: relative;
      padding: 8px 12px 8px 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 12px;
      cursor: default;

      &.with-controls {
        padding-right: 35px;
      }

      &:hover {
        background-color: rgba(#f6f6f8, 1);
      }

      &.active {
        background-color: $accent-color-8;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          height: calc(100% - 2px);
          width: 2px;
          margin: auto;
          background-color: $accent-color-1;
        }
      }

      &.loader {
        justify-content: center;
        &:hover {
          background-color: transparent;
        }
      }

      &.no-data {
        justify-content: center;
        opacity: 0.5;
        &:hover {
          background-color: transparent;
        }
      }

      &.no-hover {
        &:hover {
          background-color: transparent;
        }
      }

      &:first-child {
        .camera-preview__image.scaled {
          top: 0;
        }
      }

      &:last-child {
        .camera-preview__image.scaled {
          top: auto;
          bottom: 0;
        }
      }

      .camera-preview {
        position: relative;
        height: 57px;
        min-width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__image {
          max-height: 100%;
          max-width: 100%;
          border-radius: 8px;
          &.scaled {
            position: absolute;
            max-width: 280px;
            height: 158px;
            top: -40;
            left: 0;
            max-height: none;
            max-width: none;
            z-index: 100;
            object-fit: contain;
            object-position: left;
          }
        }

        .load-error {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $border-gray-color;
          border-radius: 8px;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .camera-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        row-gap: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .camera-name {
        position: relative;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .camera-sub-info {
        width: 100%;
        font-size: 12px;
        color: $gray-color-secondary;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .camera-tools {
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 4px;
      }

      .camera-status {
        width: 10px;
        height: 10px;
        border-radius: 8px;
      }
    }
  }
}

@media (max-width: $xs) {
  .cameras-sidebar {
    flex-basis: 100%;
    border: none;
    border-radius: 0;
    &__list {
      padding-right: 24px;
      &-item:not(.loader) {
        border-top: 1px solid $border-gray-color;
        &::after {
          content: url('../../assets/img/icons/common/ChevroneDown.svg');
          opacity: 0.5;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
        &.active {
          background-color: inherit;
          &::before {
            content: none;
          }
          &::after {
            content: url('../../assets/img/icons/common/ChevroneUp.svg');
          }
        }
        &.rightChevron {
          &::after {
            content: url('../../assets/img/icons/common/ChevroneRight.svg');
          }
        }
      }
    }
  }
}
