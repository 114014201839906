@import '../../assets/scss/variables.scss';

.notifications__container {
  height: 100%;
  overflow-y: auto;
  padding: 0 28px 28px;

  .notification__li {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid $border-gray-color;
    padding: 6px 0;
    &-item {
      max-width: 286px;
      width: max-content;
      padding: 6px 0;
      gap: 8px;
      cursor: default;
      &:hover {
        background-color: inherit !important;
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      gap: 6px;
      max-width: 258px;
    }
    &-span {
      width: max-content;
      max-width: 100%;
      overflow: hidden;
      hyphens: auto;
      white-space: pre-line;
      overflow-wrap: break-word;
      color: $gray-color-body;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      a {
        color: $accent-color-1;
      }
    }
    &-time {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: $gray-color-tertiary;
    }
    .notification-icon {
      &.info > svg {
        color: $info-color-1;
      }
      &.warn > svg {
        color: $warning-color-1;
      }
      &.error > svg {
        color: $accent-color-1;
      }
      svg {
        display: block;
        width: 20px;
        height: 20px;
      }
    }

    &-delete {
      width: 36px;
      height: 36px;
      padding: 8px;
      border-radius: 16px;
      cursor: pointer;
      transition: 0.5s;
      svg {
        width: 20px;
        height: 20px;
        color: $gray-color-tertiary;
      }
      &:hover {
        background-color: $gray-color-6;
      }
      &:active {
        background-color: $gray-color-5;
      }
    }
    &.unread {
      background-color: #eeeeee;
    }
    &-disabled {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 5px;

      svg {
        width: 24px;
        height: 24px;
        color: $info-color-1;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: $gray-color-2;
      }
    }
  }
}
.notifications-container {
  position: relative;
  .header-bell__notifications {
    width: max-content;
    min-width: 18px;
    height: 18px;
    position: absolute;
    top: 3px;
    right: 3px;
    padding: 1px;
    border-radius: 9px;
    background-color: $accent-color-1;
    border: 2px solid $color-white;
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 9px;
    font-size: 11px;
    font-weight: 600;
  }
}
