@import '../../assets/scss/variables.scss';
.conditions {
  &-item {
    display: flex;
    height: 20px;
    align-items: center;
    &--icon {
      height: 20px;
      width: 20px;
      padding: 5px;
      &-red {
        color: $red;
      }
      &-green {
        color: $success-color-1;
      }
    }
    span {
      font-size: 14px;
    }
  }
}
