@import '../../../assets/scss/variables';

.transparent-select {
  position: relative;
  height: 32px;
  &.opened {
    .filter__toggle {
      background-color: $gray-color-4;
      &:hover {
        background-color: $gray-color-4;
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    .filter__toggle {
      &:hover {
        background-color: transparent;
      }
    }
  }

  .filter__toggle {
    height: 100%;
    padding: 6px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: $border-radius-primary;
    background-color: transparent;
    line-height: 1;
    cursor: default;
    &:hover {
      background-color: $gray-color-6;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .filter__label {
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
      color: $gray-color-body;
    }
  }

  .filter__dropdown-menu {
    position: absolute;
    top: 36px;
    border-radius: $border-radius-dropdown-list;
    background-color: white;
    box-shadow: $shadow-context-menu;
    z-index: 10;
    &.open-up {
      top: auto;
      bottom: 36px;
    }

    &__options {
      max-height: 300px;
      padding: 4px;
      overflow: auto;
      .option {
        padding: 9px 12px 9px 12px;
        white-space: nowrap;
        border-radius: 4px;
        cursor: default;
        &:hover {
          background-color: $gray-color-6;
        }
        &.active {
          background-color: $gray-color-5;
        }
      }
    }
  }
}

@media (max-width: $xs) {
  .table-pagination {
    .filter__dropdown-menu {
      top: -100px;
      right: 10px;
      position: absolute;
      border-radius: $border-radius-dropdown-list;
      background-color: white;
      box-shadow: $shadow-context-menu;
      z-index: 10;
      &__options {
        max-height: 300px;
        padding: 4px;
        overflow: auto;
        .option {
          padding: 9px 12px 9px 12px;
          white-space: nowrap;
          border-radius: 4px;
          cursor: default;
          &:hover {
            background-color: $gray-color-6;
          }
          &.active {
            background-color: $gray-color-5;
          }
        }
      }
    }
  }
}
