@import '../../../assets/scss/variables.scss';

.custom-cell__datetime {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  span {
    white-space: nowrap;
    &.secondary {
      color: $gray-color-tertiary;
    }
  }
}
