@import '../../../assets/scss/variables';

.play-button {
  width: 52px;
  height: 52px;
  padding: 4px;
  border-radius: 30px;
  border: 1px solid $accent-color-1;
  background-color: $accent-color-10;

  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: 1px solid $accent-color-1;
    background-color: $color-white;

    svg {
      width: 20px;
      height: 20px;
      margin-left: 4px;
      color: $accent-color-1;
    }
  }
}
