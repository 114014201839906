@import '../../../assets/scss/variables';

.multiple-select-filter {
  position: relative;
  height: 36px;
  &.opened {
    .filter__toggle {
      background-color: $gray-color-4;
      &:hover {
        background-color: $gray-color-4;
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    .filter__toggle {
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }
  }

  .filter__toggle {
    height: 100%;
    padding: 8px 10px 8px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: $border-radius-primary;
    background-color: $gray-color-6;
    line-height: 1;
    cursor: default;
    &:hover {
      background-color: $gray-color-5;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    .filter__label {
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
    }

    .filter__clear {
      width: 22px;
      height: 22px;
      margin-left: 8px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 2px solid white;
      background-color: $gray-color-6;
      color: $gray-color-body;
      svg {
        width: 16px;
        height: 16px;
      }
      &:hover {
        background-color: $gray-color-5;
      }
    }
  }
}

.filter__multiple-dropdown-menu {
  border-radius: $border-radius-dropdown-list;
  background-color: white;
  box-shadow: $shadow-context-menu;
  z-index: 10000;

  &__search {
    height: 36px;
    padding: 8px 4px 8px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
    border-bottom: 1px solid $border-gray-color;
    svg {
      width: 20px;
      height: 20px;
      opacity: 0.5;
    }
    input {
      height: 18px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &__buttons {
    padding: 4px 4px 0 4px;
    .filter__main-button {
      height: 36px;
      padding: 10px 12px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
      text-align: center;
      color: $red;
      &:hover {
        background-color: $accent-color-5;
      }
    }
  }

  &__options {
    max-height: calc(var(--data-max-height) - 48px);

    &.searchable {
      max-height: calc(var(--data-max-height) - 84px);
    }
    padding: 4px;
    overflow: auto;
    .option {
      padding: 9px 12px 9px 36px;
      white-space: nowrap;
      border-radius: 4px;
      cursor: default;
      &:hover {
        background-color: $gray-color-6;
      }
      &.active {
        background-color: $gray-color-5;
        padding: 8px 12px 8px 12px;
      }
      svg {
        margin-right: 4px;
        width: 20px;
        height: 20px;
      }
    }
  }
}
