@import '../../assets/scss/variables';

.time-control__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  .number-input {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 5px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 10px;
    }

    &__label {
      margin-bottom: 10px;
      font-size: 12px;
    }

    &__previous-number,
    &__next-number {
      color: rgba(black, 0.5);
      font-size: 16px;
      cursor: pointer;
    }

    &__input {
      width: 50px;
      padding: 10px 0;
      border-top: 2px solid $accent-color-1;
      border-bottom: 2px solid $accent-color-1;
      color: rgba(#1d2429, 0.8);
      font-size: 24px;
      text-align: center;
    }
  }
}

.time-control-with-options {
  position: relative;
  height: 36px;
  width: 90px;
  padding: 0 10px;
  display: flex;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  &.time-control-with-options__datepicker {
    width: 88px;
    text-overflow: ellipsis;
    border-radius: 18px;
    border: none;
    background-color: $gray-color-6;
    padding: 9px 12px;
    text-align: center;
  }
  &.time-control-with-options__catering {
    width: 100%;
    height: 44px;
    text-overflow: ellipsis;
    border-radius: 18px;
    border: none;
    background-color: $gray-color-6;
    padding: 16px 12px 4px;
    text-align: center;
    font-size: 16px;
  }
  &.invalid {
    background-color: rgba($color-pink, 0.05);
    border: 1px solid rgba($color-pink, 0.5);
  }

  input {
    font-stretch: condensed;
    color: $gray-color-body;
    width: 100%;
  }
}

.time-control {
  &__options {
    z-index: 10000;
    padding: 10px 0;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    border: 1px solid rgba(black, 0.1);
    border-radius: 6px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);

    &-item {
      text-align: left;
      padding: 0 15px;
      line-height: 40px;
      min-width: 100px;
      white-space: nowrap;
      color: black;
      cursor: default;
      &:hover {
        background-color: rgba(black, 0.1);
      }
    }
  }
}
