@import '../variables.scss';

.mobile-table {
  &__container {
    width: 100%;
    &.inner-scroll {
      overflow-y: auto;
    }
  }

  &__wrapper {
    width: 100%;
    border-radius: 18px;
    border: 1px solid $border-gray-color;
    overflow-x: auto;
  }

  &__table {
    width: 100%;
  }

  &__thead {
    border-bottom: 1px solid $border-gray-color;
    font-size: 12px;
    font-weight: 700;
    color: $gray-color-tertiary;
  }

  &__header-cell {
    padding: 8px 16px 7px 8px;
    vertical-align: middle;
    &:first-child {
      padding-left: 28px;
    }
    &:last-child {
      padding-right: 28px;
    }
  }

  &__tbody {
    &-row {
      height: 42px;
      border-bottom: 1px solid $border-gray-color;
      &.opened {
        border-bottom: none;
      }
      &:last-child {
        border-bottom: none;
      }
      &.selectable {
        &:hover {
          background-color: rgba($gray-color-6, 0.5);
        }
      }
      .custom-table__body-cell {
        overflow-wrap: anywhere;
      }
    }
  }
  &__extended-info {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 0 16px 16px 48px;
    flex-direction: column;
    gap: 12px;
    &__item {
      display: flex;
      flex-direction: row;
      gap: 4px;
      width: 100%;
    }
    &__header {
      width: 25%;
      color: $gray-color-secondary;
      font-size: 12px;
      word-wrap: break-word;
    }
    &__label {
      width: 74%;
      word-wrap: break-word;
      word-break: normal;
    }
  }

  &__body-cell {
    padding: 9px 16px 9px 8px;
    vertical-align: top;
    font-size: 14px;
    font-weight: 400;
    &:first-child {
      padding: 9px 9px 9px 16px;
    }
    &:last-child {
      padding-right: 16px;
    }
  }

  &__footer-cell {
    padding: 9px 16px 9px 8px;
    vertical-align: top;
    height: 42px;
    &:first-child {
      padding-left: 28px;
    }
    &:last-child {
      padding-right: 28px;
    }
  }
  &__tfoot {
    border-top: 1px solid $border-gray-color;
    font-weight: 600;
    color: $gray-color-body;
  }

  &__menu-cell {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    column-gap: 24px;
    &__button {
      padding: 0;
      color: $gray-color-tertiary;
      &-red {
        color: $accent-color-1;
      }
      &.chevron svg {
        width: 16px;
        height: 16px;
      }
    }
    &__download-btn {
      width: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 6px;
      &.loading {
        button {
          color: $accent-color-1;
        }
      }
    }
  }

  &__bold-text {
    font-weight: 600;
  }
}
