@import url('https://use.fontawesome.com/releases/v5.13.0/css/all.css');
@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.zoomed--image--modal {
  max-width: 80vw;
  width: 100%;
  height: 100%;
  max-height: 90vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  &--opacity {
    max-width: 80vw;
    width: 100%;
    height: 100%;
    max-height: 90vh;
    position: relative;
    margin: 0 auto;
    opacity: 0;
    z-index: 0;
  }
}
.modal-dialog--support {
  max-width: 50% !important;
  width: max-content !important;
  .modal-header {
    display: inline-flex;
  }
  .modal_body_history {
    padding: 0 !important;
  }
}
.modal-dialog--unusual {
  width: 100%;
  padding: 0 10%;
  .modal-header {
    display: inline-flex;
  }
  .modal_body_history {
    overflow: hidden;
    height: 100%;
    padding: 0 !important;
    &--overflow-visible {
      overflow: visible;
    }
  }
  &-wide {
    max-width: 100% !important;
    .modal-content {
      max-width: 80%;
      margin: 0 auto;
      border: none;
      max-height: calc(100vh - 3.5rem);
      .modal_body_history {
        height: 100%;
        max-height: calc(100vh - 3.5rem);
        padding: 0 !important;
        overflow: visible;
        .images-slider--container {
          width: 100%;
          height: 100%;
          background-color: transparent;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          & > div {
            width: max-content;
            max-width: 100%;
            height: max-content;
            max-height: calc(100vh - 3.5rem);
            margin: auto;
            position: relative;
            overflow: hidden;
            img {
              height: calc(100% + 1px);
            }
          }
        }
        .slick-slider {
          width: 100%;
          margin: auto;
          .slick-track {
            display: flex;
            align-items: center;
          }
          .slick-prev {
            position: fixed;
            left: 0px;
            padding-left: 20px;
            text-align: left;
            width: 70px;
            height: 100%;
            background-color: transparent;
            &:before {
              opacity: 0.1;
              font-family: 'Font Awesome 5 Free';
              content: '\f053';
              font-weight: 600;
              fill: white;
              color: white;
              font-size: 50px;
              transition: 0.3s;
            }
            &:hover::before {
              opacity: 1;
              transition: 0.3s;
            }
          }
          .slick-next {
            position: fixed;
            right: 0px;
            text-align: right;
            padding-right: 25px;
            width: 70px;
            height: 100%;
            &::before {
              opacity: 0.1;
              font-family: 'Font Awesome 5 Free';
              content: '\f054';
              font-weight: 600;
              fill: white;
              color: white;
              font-size: 50px;
              transition: 0.3s;
            }
            &:hover::before {
              transition: 0.3s;
              opacity: 1;
            }
          }
          .slick-dots > li {
            > button::before {
              font-size: 50px;
              width: 50px;
              height: 100px;
            }
            &.slick-active button:before {
              fill: white;
              width: 50px;
              height: 100px;
              font-size: 50px;
            }
          }
          .slick-slide {
            background-color: rgba(0, 0, 0, 0) !important;
            & > div {
              background-color: rgba(0, 0, 0, 0) !important;
            }
          }
        }
      }
    }
  }
}
.modal--image--zoomed {
  max-height: calc(100% - 3.5rem);
  margin: 1.75rem 0 !important;
  .modal-content {
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

.image-zoomer--switchable-image {
  position: relative;
  width: max-content;
  display: flex;
  justify-content: center;

  .image-zoomer--switcher {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    column-gap: 5px;
    z-index: 5;

    &--item {
      width: 30px;
      height: 30px;
      padding: 6px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      cursor: pointer;
      color: $gray-color-body;
      background-color: $color-white;
      box-shadow: $shadow-dropdown-menu;
      &.active {
        color: $color-white;
        background-color: $accent-color-1;
      }
    }
  }
}

.zoomed-image--nav-icons {
  @include position(fixed, 24px 24px none none);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 5px;
  color: $color-white;
  z-index: 1100;
  svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}
