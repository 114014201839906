@import '../../assets/scss/variables';

.custom-switch {
  padding: 11px 8px;
  transition: all 0.3s;

  &__bar {
    position: relative;
    height: 10px;
    width: 30px;
    background-color: $gray-color-3;
    border-radius: 20px;
  }

  &__thumb {
    position: absolute;
    height: 28px;
    width: 28px;
    top: -9px;
    left: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    &-main {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: $color-white;
      box-shadow: 0 1px 3px 0 rgba($color-black, 0.2), 0 2px 1px 0 rgba($color-black, 0.12);
    }
  }

  &:active {
    .custom-switch__bar {
      background-color: $gray-color-secondary;
    }
    .custom-switch__thumb {
      background-color: #AEB5BC66;
    }
  }
  &:not(&:active):hover {
    .custom-switch__bar {
      background-color: $gray-color-tertiary;
    }
    .custom-switch__thumb {
      background-color: #AEB5BC33;
    }
  }
  &:disabled {
    opacity: 0.5;
  }

  &.active {
    .custom-switch__bar {
      background-color: rgba($accent-color-1, 0.5);
    }

    .custom-switch__thumb {
      left: auto;
      right: -6px;
      &-main {
        background-color: $accent-color-1;
      }
    }

    &:active {
      .custom-switch__bar {
        background-color: rgba($accent-color-3, 0.5);
      }
      .custom-switch__thumb {
        left: auto;
        right: -6px;
        background-color: $accent-color-6;
        &-main {
          background-color: $accent-color-3;
        }
      }
    }
    &:not(&:active):hover {
      .custom-switch__bar {
        background-color: rgba($accent-color-2, 0.5);
      }
      .custom-switch__thumb {
        left: auto;
        right: -6px;
        background-color: $accent-color-7;
        &-main {
          background-color: $accent-color-2;
        }
      }
    }
    &:disabled {
      opacity: 0.5;
    }
  }
}
