@import '../../assets/scss/variables';

.image-preview {
  &__container {
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__no-image {
    position: absolute;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $gray-color-tertiary;
    white-space: nowrap;
  }

  &__image {
    max-width: 400px;
    max-height: 100%;
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
}
