@import '../../assets/scss/variables.scss';

.small-bar-chart-with-table-content {
  .chart-wrapper {
    display: flex;
    column-gap: 30px;
  }

  .small-bar__chart {
    flex: 1;
    min-width: 0;
  }

  .total-wrapper {
    width: 300px;

    @media (max-width: $xs) {
      width: 100%;
    }
  }
}
