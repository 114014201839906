@import '../variables.scss';

.unusual-table {
  &__container {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(1, 1fr);
  }
  &__item {
    border-radius: 18px;
    border: 1px solid $border-gray-color;
    display: flex;
    padding: 8px;
    gap: 12px;
  }
  &__component {
    width: 50%;
    .unusual__image {
      border-radius: 12px;
    }
  }
  &__description {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 5px;
    height: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    div {
      display: flex;
      svg {
        display: block;
        width: 16px;
        height: 16px;
        color: $gray-color-tertiary;
        margin-right: 2px;
      }
    }
  }
}

.unusual {
  &__icon-container {
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &__table-content {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
  }

  &__image {
    width: max-content;
    max-width: 100%;
    border-radius: 18px;
    border-color: inherit;
    cursor: pointer;
    &-table {
      min-width: 150px;
      max-width: min(100%, 800px);
      width: max-content;
    }
  }
}
