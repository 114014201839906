@import '../../assets/scss/variables';

.nav-bar-inner {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $border-gray-color;

  &__container {
    width: 100%;
    overflow-x: auto;

    &.sticky {
      position: sticky;
      top: 0;
      display: flex;
      background-color: $color-white;
      z-index: 10;
      border-bottom: 1px solid $border-gray-color;
      .nav-bar-inner {
        flex: 1;
        border: none;
        overflow: auto;

        &::-webkit-scrollbar {
          height: 4px;
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }

    &::-webkit-scrollbar {
      height: 2px;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .nav-item {
    height: 100%;
    padding: 9px 24px;
    font-size: 14px;
    font-weight: 600;
    color: $gray-color-secondary;
    white-space: nowrap;
    cursor: pointer;

    &.active {
      color: $red;
      border-bottom: 2px solid $red;
    }
  }

  &__right-tools {
    margin-left: auto;
  }
}

@media (max-width: $xs) {
  .nav-bar-inner {
    height: max-content;
    overflow-x: auto;
    border: 1px solid $border-gray-color;
    border-radius: 18px;
    padding: 4px;
    gap: 4px;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
    .nav-item {
      padding: 10px 12px;
      span {
        white-space: nowrap;
      }
      &.active {
        color: $red;
        background-color: $accent-color-8;
        border-bottom: 0px !important;
        border-radius: 18px;
      }
    }
    &__border-box {
      .nav-item {
        box-sizing: border-box;
        width: 100%;
        text-align: center;
      }
    }
  }
}
