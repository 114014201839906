@import '../../../assets/scss/variables.scss';

.form-checkbox {
  height: 18px;
  position: relative;
  width: 100%;
  &.without-name {
    width: 28px;
    height: 28px;
    cursor: pointer;
    div {
      top: -4px;
      left: -4px;
    }
    span {
      display: block;
      left: 5px;
      top: 5px;
      &::before {
        left: 0;
      }
    }
  }
  label {
    margin-left: 26px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    cursor: pointer;
    &.bold {
      font-weight: 600;
    }
  }
  &__input {
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: -1;
  }
  &__input + div,
  &__background {
    position: absolute;
    width: 36px;
    height: 36px;
    top: -9px;
    left: -9px;
    border-radius: 18px;
  }

  &__input ~ span,
  &__background > span {
    z-index: 2;
    height: 18px;
    width: 18px;
    display: inline-block;
    border: 2px solid $gray-color-secondary;
    position: absolute;
    left: 9px;
    top: 9px;
    cursor: pointer;
    &.rounded {
      border-radius: 1px;
    }
  }

  &.without-name:hover {
    input:checked + div {
      background-color: $accent-color-7;
    }
    input:checked ~ span {
      background-color: $accent-color-2;
      border-color: $accent-color-2;
    }
    div {
      background-color: $gray-color-6;
    }
    input.disabled + div {
      background-color: $color-transparent;
    }
  }
  &__input {
    &:not(:checked) {
      & + .form-checkbox__background:hover {
        background-color: $gray-color-6;
        span {
          border-color: $gray-color-2;
        }
      }
    }
    .disabled + .form-checkbox__background:hover,
    .disabled + .form-checkbox__background:active {
      background-color: $color-transparent;
    }
    & + .form-checkbox__background:active {
      background-color: $gray-color-5;
    }
    &:checked {
      & + .form-checkbox__background:hover {
        background-color: $accent-color-7;
        span {
          background-color: $accent-color-2;
          border-color: $accent-color-2;
        }
      }
      & + .form-checkbox__background:active {
        background-color: $accent-color-6;
        span {
          background-color: $accent-color-3;
          border-color: $accent-color-3;
        }
      }
    }
  }
  &.without-name:active {
    input:checked + div {
      background-color: $accent-color-6;
    }
    input:checked ~ span {
      background-color: $accent-color-3;
      border-color: $accent-color-3;
    }
    div {
      background-color: $gray-color-5;
    }
    input:not(:checked) ~ span {
      border-color: $gray-color-2;
    }
    input.disabled + div {
      background-color: $color-transparent;
    }
  }
  input:checked ~ span,
  input:checked + .form-checkbox__background > span {
    background-color: $accent-color-1;
    border-color: $accent-color-1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input.disabled:checked ~ span,
  input.disabled:checked + .form-checkbox__background > span {
    opacity: 0.5;
    cursor: not-allowed;
  }
  input.disabled:not(:checked) ~ span,
  input.disabled:not(:checked) + .form-checkbox__background > span {
    cursor: not-allowed;
    background-color: $gray-color-6;
  }
  input:checked ~ span::before,
  input:checked + .form-checkbox__background > span::before {
    display: block;
    position: absolute;
    content: url('../../../assets/img/icons/common/Checkbox.svg');
    width: 12px;
    left: 0.5px;
    font-size: 14px;
    line-height: 14px;
    color: $form-white;
  }
}
