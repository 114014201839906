@import '../../assets/scss/variables.scss';

.login-container__footer {
  width: 100%;
  min-height: 58px;
  background-color: $second-background-color;
  border-top: 1px solid $border-gray-color;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.5;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 12px;
  height: max-content;

  @media (max-width: $xs) {
    flex-direction: column;
  }

  &-item {
    text-decoration: none;
    color: $gray-color-tertiary;
    line-height: 17px;
    &:hover {
      color: $color-black;
    }
  }
  &__container {
    display: flex;
    gap: 12px 24px;
    flex-wrap: wrap;
    max-width: 50%;
    &-right {
      justify-content: start;
    }
  }
  &__email {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 6px;
    color: $gray-color-1;
    text-decoration: underline;
    &:hover {
      color: $gray-color-1;
    }

    svg {
      width: 18px;
      height: 18px;
      color: $accent-color-1;
    }
  }
}
