@import '../../../assets/scss/variables.scss';

.filters-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: $border-radius-primary;
  color: $gray-color-body;
  position: relative;

  &:hover {
    background-color: $gray-color-6;
  }
  &:active {
    background-color: $gray-color-4;
  }
  &:disabled {
    opacity: 0.5;
    &:hover {
      background-color: transparent;
    }
  }
  &__quantity {
    position: absolute;
    top: 5px;
    right: 4px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: $accent-color-1;
    color: $color-white;
    font-size: 11px;
    border: 2px solid $color-white;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}
