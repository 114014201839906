@import '../../assets/scss/variables.scss';

.dropdown-menu {
  border: none !important;
  box-shadow: $shadow-dropdown-menu;
  padding: 4px !important;
  top: 52px;
  width: max-content;
  min-width: 0 !important;
  > li > a {
    padding: 9px 12px;
    display: block;
    font-size: 14px;
    color: $gray-color-body;
    white-space: normal !important;
    cursor: pointer;
    &.disabled {
      color: dimgray !important;
      cursor: default;
    }
  }
}

.custom-dropdown {
  &__container {
    position: relative;
    &.opened {
      .custom-dropdown__toggle {
        background-color: $gray-color-6;
      }
    }
    &.disabled {
      opacity: 0.5;
      .custom-dropdown__toggle {
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  &__toggle {
    border-radius: $border-radius-primary;
    height: max-content;
    width: max-content;
  }
  &__menu-options {
    position: absolute;
    top: 46px;
    right: 0;
    border-radius: $border-radius-dropdown-list;
    background-color: white;
    padding: 4px;
    box-shadow: $shadow-context-menu;
    z-index: 10;
    &.top {
      top: auto;
      bottom: 46px;
    }
  }
  &__item {
    text-align: left;
    padding: 9px 12px 9px 12px;
    white-space: nowrap;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: $gray-color-6;
    }
    &.active {
      background-color: $gray-color-5;
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
