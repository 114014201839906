@import '../../../assets/scss/variables';

.custom-search__container {
  height: 36px;
  padding: 2px 4px 2px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  border-radius: $border-radius-primary;
  background-color: $gray-color-6;
  color: $gray-color-tertiary;
  box-sizing: border-box;

  &.focused {
    padding: 1px 4px 1px 12px;
    border: 1px solid $accent-color-1;
    background-color: transparent;
    color: $accent-color-1;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  .custom-search {
    &__input {
      flex-grow: 1;
      height: 20px;
      color: $gray-color-body;
      font-size: 16px;
      caret-color: $accent-color-1;
      line-height: 1;
    }

    &__clear-btn {
      width: 32px;
      height: 32px;
      padding: 6px;
      color: $gray-color-tertiary;
      border-radius: 16px;
      &:hover {
        color: $gray-color-secondary;
        background-color: $gray-color-6;
      }
      &:active {
        color: $gray-color-2;
        background-color: $gray-color-5;
      }
    }
  }
}
