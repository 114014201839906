@import '../../assets/scss/variables.scss';

.datepicker__container {
  height: 36px;
  width: max-content;
  display: flex;
  gap: 6px;
  color: $gray-color-body;
  position: relative;

  .datepicker {
    &__arrow {
      width: 36px;
      border-radius: 18px;
      padding: 9px 10px;
      background-color: $gray-color-6;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background-color: $gray-color-5;
      }
      &:active {
        background-color: $gray-color-4;
      }
      svg {
        width: 16px;
        height: 16px;
      }
      &__disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.5 !important;
      }
    }
    &__range {
      padding: 7px 10px;
      border-radius: 18px;
      background-color: $gray-color-6;
      width: max-content;
      min-width: 300px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;
      text-align: center;
      &.mini {
        min-width: 250px;
      }
      &:hover {
        background-color: $gray-color-5;
      }
      &.active {
        background-color: $gray-color-4;
        .datepicker__interval-short {
          background-color: $gray-color-3;
        }
      }
      .datepicker__interval-short {
        height: 22px;
        padding: 2px 8px;
        border-radius: 14px;
        background-color: $gray-color-4;
        margin-right: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        line-height: 1;
        svg {
          height: 16px;
          fill: $gray-color-body;
        }
      }
      .datepicker__interval-text {
        word-break: normal;
        width: max-content;
        max-width: 100%;
        &__placeholder {
          width: 100%;
          text-align: center;
          color: $gray-color-tertiary;
        }
      }
    }
  }
  .datepicker-ranges__container {
    width: 100%;
    position: absolute;
    z-index: 5;
    top: 40px;
  }
  .datepicker-ranges {
    background-color: $second-background-color;
    width: max-content;
    height: max-content;
    padding: 4px;
    border-radius: 4px;
    gap: 1px;
    &__left-aligned {
      margin: 0 auto 0 42px;
      box-shadow: $shadow-dropdown-menu;
    }
    &__centered {
      margin: 0 auto;
      box-shadow: $shadow-dropdown-menu;
    }
    &__scrolled {
      max-height: 100%;
      overflow-y: scroll;
      padding-top: 24px;
    }
    &-item {
      cursor: pointer;
      height: 33px;
      padding: 7px 24px 7px 12px;
      &:hover {
        background-color: $gray-color-6;
      }
      &__active {
        color: $red;
        font-weight: 500;
        background-color: $accent-color-8;
        &:hover {
          background-color: $accent-color-7;
        }
      }
      &__disabled {
        cursor: default;
      }
      &__clear {
        color: $red;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        svg {
          margin: auto 4px auto 0;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .datepicker-custom-range {
    z-index: 3;
    background-color: $second-background-color;
    box-shadow: $shadow-dropdown-menu;
    border-radius: 20px;
    position: absolute;
    top: 40px;
    right: 42px;
    width: max-content;
    height: max-content;
    &__body {
      display: flex;
      height: 334px;
      overflow-y: hidden;
    }
    &__footer {
      height: 76px;
      padding: 20px 30px;
      border-top: 1px solid $border-gray-color;
      display: flex;
      justify-content: space-between;
      &.right {
        justify-content: end;
      }
      &__time {
        &-container {
          display: flex;
          max-width: 70%;
          align-items: center;
          gap: 8px;
          .time-range__group {
            display: flex;
            align-items: center;
            gap: 6px;
          }
        }
      }
      &__buttons {
        display: flex;
        gap: 16px;
        &-item {
          width: max-content;
          height: 36px;
          padding: 10px 20px;
          border-radius: 18px;
          font-weight: 600;
          font-size: 16px;
          line-height: 1;
        }
        &-cancel {
          border: 1px solid $red;
          color: $red;
        }
        &-apply {
          background-color: $red;
          color: white;
          &.disabled {
            pointer-events: none;
            cursor: default;
            background-color: $accent-color-4;
          }
        }
      }
    }
  }
}

.datepicker-custom-range__calendar {
  border-left: 1px solid $border-gray-color;
  padding: 2px 30px 16px 24px;
  width: 540px;
  height: 334px;
  position: relative;
  .rdrDays {
    width: 231px;
    & > button {
      width: 33px;
    }
  }
  .rdrDayNumber {
    top: 3px;
    height: 33px;
    bottom: 0;
    & span {
      font-size: 14px;
      font-weight: 400;
      line-height: 2.5;
    }
  }
  .rdrNextPrevButton {
    width: 16px;
    height: 16px;
    margin: 8px;
    i {
      display: none;
    }
  }
  .rdrPrevButton,
  .rdrPprevButton {
    background: url('../../assets/img/icons/datepicker/ArrowLeft.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
  .rdrNextButton {
    background: url('../../assets/img/icons/datepicker/ArrowRight.svg');
    background-position: center;
    background-repeat: no-repeat;
    fill: $gray-color-body;
  }
  .rdrMonthAndYearPickers {
    display: none;
  }
  .rdrMonths {
    gap: 24px;
  }
  .rdrMonth {
    padding: 0;
    &:first-child .rdrMonthName {
      left: 100px;
    }
    &:last-child .rdrMonthName {
      right: 100px;
    }
  }
  .rdrMonthName {
    position: absolute;
    top: 18px;
    font-size: 14px;
    font-weight: 600;
    color: $gray-color-body;
  }
  .rdrDayStartOfMonth:not(.rdrDayStartOfWeek) .rdrInRange,
  .rdrDayStartOfMonth:not(.rdrDayEndOfWeek) .rdrEndEdge:not(.rdrStartEdge),
  .rdrDayStartOfMonth:not(.rdrDayStartOfWeek) .rdrDayInPreview,
  .rdrDayStartOfMonth:not(.rdrDayStartOfWeek) :not(.rdrInRange) ~ .rdrDayEndPreview,
  .rdrDayStartOfMonth:not(.rdrDayStartOfWeek) :not(.rdrInRange) ~ .rdrDayStartPreview {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0;
  }
  .rdrDayStartOfMonth:not(.rdrDayStartOfWeek) .rdrDayInPreview,
  .rdrDayEndOfMonth:not(.rdrDayEndOfWeek) .rdrDayInPreview {
    border-right-width: 0;
    border-left-width: 0;
  }
  .rdrDayEndOfMonth:not(.rdrDayEndOfWeek) .rdrInRange,
  .rdrDayEndOfMonth:not(.rdrDayEndOfWeek) .rdrStartEdge:not(.rdrEndEdge),
  .rdrDayEndOfMonth:not(.rdrDayEndOfWeek) .rdrDayInPreview,
  .rdrDayEndOfMonth:not(.rdrDayEndOfWeek) :not(.rdrInRange) ~ .rdrDayStartPreview {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .rdrDayStartOfWeek .rdrInRange,
  .rdrStartEdge {
    border-top-left-radius: 16.5px;
    border-bottom-left-radius: 16.5px;
  }
  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfWeek .rdrDayStartPreview,
  .rdrEndEdge {
    border-top-right-radius: 16.5px;
    border-bottom-right-radius: 16.5px;
  }
  .rdrStartEdge,
  .rdrEndEdge {
    height: 33px;
    width: 33px;
    left: 0px;
    top: 3px;
    font-weight: 400;
    overflow: visible;
    & ~ .rdrDayNumber {
      width: 33px;
      height: 33px;
      overflow: visible;
      border-radius: 16.5px;
      background-color: $red;
      > span {
        color: white;
      }
    }
  }
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    z-index: 0;
    height: 33px;
    bottom: 0;
    top: 3px;
    border-color: $accent-color-7;
    background-color: $accent-color-8;
  }
  .rdrDayStartPreview:not(.rdrDayEndPreview) {
    width: 33px;
    border: 1px solid $accent-color-7;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    border-right-width: 0;
    background-color: $accent-color-8;
  }
  .rdrDayEndOfWeek .rdrDayStartPreview {
    border-right-width: 1px;
  }
  .rdrInRange {
    top: 3px;
    left: 0;
    width: 33px;
    height: 33px;
    & ~ span > span {
      color: $gray-color-body !important;
    }
  }
  .rdrDayToday > span > span {
    color: $accent-color-1;
    &:after {
      display: none;
    }
  }

  .rdrDayDisabled {
    background-color: white;
    .rdrDayNumber span {
      color: $gray-color-4 !important;
    }
  }
  .rdrDayPassive {
    .rdrDayInPreview,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      display: block;
    }
    & > span.rdrInRange,
    & > span.rdrEndEdge,
    & > span.rdrStartEdge {
      display: inline;
      & ~ span:not(.rdrDayStartPreview):not(.rdrDayEndPreview) {
        background-color: inherit;
        & > span {
          color: $gray-color-body;
        }
      }
    }
  }
  .rdrDayPassive > .rdrDayNumber > span {
    color: $disabled-gray-color;
  }
  &-one-month {
    width: 334px;
    height: 392px;
    border-left: none;
    padding: 2px 0 10px;
    .rdrDayNumber {
      height: 46px;
      font-size: 18px;
    }
    .rdrMonth:first-child .rdrMonthName {
      left: 120px;
    }
    .rdrDays {
      width: 322px;
      & > button {
        width: 46px;
        height: 49px;
      }
    }
    .rdrInRange {
      width: 46px;
      height: 46px;
    }
    .rdrStartEdge,
    .rdrEndEdge {
      height: 46px;
      width: 46px;
      & ~ .rdrDayNumber {
        width: 46px;
        height: 46px;
        border-radius: 23px;
        & > span {
          font-size: 16px;
        }
      }
    }
    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
      height: 46px;
    }
    .rdrDayStartPreview:not(.rdrDayEndPreview),
    .rdrStartEdge:not(.rdrEndEdge) {
      width: 46px;
      border-top-left-radius: 23px;
      border-bottom-left-radius: 23px;
    }
    .rdrDayStartPreview.rdrDayEndPreview {
      border-radius: 23px;
    }
    .rdrDayEndPreview {
      border-top-right-radius: 23px;
      border-bottom-right-radius: 23px;
    }
    .rdrDayStartOfWeek .rdrInRange,
    .rdrDayStartOfWeek .rdrDayInPreview,
    .rdrDayStartOfWeek .rdrDayEndPreview,
    .rdrDayStartOfWeek .rdrEndEdge,
    .rdrStartEdge {
      border-top-left-radius: 23px;
      border-bottom-left-radius: 23px;
    }
    .rdrDayEndOfWeek .rdrInRange,
    .rdrDayEndOfWeek .rdrDayInPreview,
    .rdrDayEndOfWeek .rdrStartEdge,
    .rdrDayEndOfWeek .rdrDayStartPreview,
    .rdrEndEdge {
      border-top-right-radius: 23px;
      border-bottom-right-radius: 23px;
    }
  }
}

.mobile-datepicker {
  display: flex;
  flex-direction: column;
  gap: 4px;
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: $gray-color-secondary;
    gap: 16px;
  }
  &__clear {
    margin-top: 8px;
    padding: 8px 20px 8px 14px;
    width: max-content;
    cursor: pointer;
    color: $red;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    svg {
      margin: auto 4px auto 0;
      width: 18px;
      height: 18px;
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 4px;
  }
  &__item {
    height: 36px;
    border-radius: 18px;
    padding: 0px 12px;
    width: 170px;
    font-size: 16px;
    background-color: $gray-color-6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &-date {
      line-height: 1;
      display: flex;
      align-items: center;
      gap: 6px;
      color: $gray-color-body;
      svg {
        width: 16px;
        height: 16px;
      }
      &.empty {
        color: $gray-color-tertiary;
      }
    }
    &-time {
      width: 170px !important;
      border: none !important;
      border-radius: 18px !important;
      background-color: transparent !important;
      padding: 0 40px 0 30px !important;
      &__container {
        width: 170px;
        position: relative;
        font-size: 16px;
        background-color: $gray-color-6;
        border-radius: 18px;
        &.empty > .mobile-datepicker__clock,
        &.empty > .mobile-datepicker__item-time {
          pointer-events: none;
          color: $gray-color-tertiary;
          input {
            color: $gray-color-tertiary;
          }
        }
        .mobile-datepicker__clock {
          position: absolute;
          top: 8px;
          left: 12px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        .mobile-datepicker__clock-arrow {
          position: absolute;
          top: 6px;
          right: 10px;
        }
      }
      .time-control__options {
        z-index: 5;
      }
    }
  }
}
