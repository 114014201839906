@import '../../../assets/scss/variables';

.page-search__container {
  box-sizing: border-box;
  padding: 2px 4px 2px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  border-radius: $border-radius-primary;
  background-color: $gray-color-6;
  color: $gray-color-tertiary;

  &.focused {
    padding: 1px 4px 1px 12px;
    border: 1px solid $accent-color-1;
    background-color: transparent;
    color: $accent-color-1;
  }

  &.closed {
    padding: 10px;
    color: $gray-color-body;
    background-color: transparent;
    border: none;
    &:hover {
      background-color: $gray-color-6;
    }

    .page-search__input {
      display: none;
    }
    .page-search__close-btn {
      display: none;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }

  .page-search {
    &__input {
      color: $gray-color-body;
      width: 116px;
      height: 18px;
      font-size: 14px;
      caret-color: $accent-color-1;
    }

    &__close-btn {
      width: 32px;
      height: 32px;
      padding: 6px;
      color: $gray-color-tertiary;
      border-radius: 16px;
      &:hover {
        color: $gray-color-secondary;
        background-color: $gray-color-6;
      }
      &:active {
        color: $gray-color-2;
        background-color: $gray-color-5;
      }
    }
  }
}

.mobile-page-search {
  height: 44px;
  &:not(.closed) {
    height: 100%;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    border-radius: $border-radius-primary;
    color: $gray-color-body;
    width: 44px;
    height: 44px;
    cursor: pointer;
    &:hover {
      background-color: $gray-color-6;
    }
    &.focused {
      background-color: $gray-color-4;
    }
    .mobile-page-search__item {
      position: absolute;
      top: calc(100% + 5px);
      left: 0px;
      width: calc(100vw - 40px);
      height: 36px;
      background-color: $gray-color-6;
      border-radius: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2px 4px 2px 16px;
      font-size: 16px;
      input {
        width: calc(100% - 20px);
      }
      svg {
        color: $gray-color-tertiary;
        width: 20px;
        height: 20px;
      }
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
