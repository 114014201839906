@import '../../assets/scss/variables';

.analytics-content {
  min-height: 500px;
  height: 100%;
  .timeseries__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    row-gap: 16px;

    .timeseries__toolbar {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      column-gap: 12px;
    }

    .timeseries__chart {
      width: 100%;
      height: 400px;
      text {
        font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 12px;
      }
    }

    .timeseries__type-select {
      width: 135px;
    }

    .timeseries__interval-select {
      width: 100px;
    }
  }

  .bar__container {
    width: 100%;
    height: 100%;

    .bar__chart {
      width: 100%;
      min-height: 400px;
      height: 100%;
      .bar-chart__analytics {
        text {
          font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
          font-size: 12px;
        }
        div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(4) > g:nth-child(1) > text {
          opacity: 0;
        }
        div > div > div > svg > g > g > g > g > text,
        div > div > div > svg > g > g > g > g > g > text {
          opacity: 0;
          animation-name: bar-chart-annotation;
          animation-duration: 0.1s;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
        }
        @keyframes bar-chart-annotation {
          0% {
            opacity: 0;
          }
          99% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
      .google-visualization-tooltip-item:first-child {
        display: none;
      }
    }
  }

  .pie__container {
    display: flex;
    align-items: flex-start;

    .chart-part {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .pie-chart__wrapper {
        position: relative;
        user-select: none;
      }
      .pie-chart__total-amount {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 32px;
        font-weight: 600;
        user-select: none;
        text-align: center;
      }
    }

    .table-part {
      flex-grow: 2;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .analytics__pie-chart-table {
    max-width: 800px;
  }

  .analytics__info-block {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 32px;
      height: 32px;
    }
  }
}

@media (max-width: $xs) {
  .analytics-content {
    min-height: auto;
    .pie__container {
      flex-direction: column;
      justify-content: center;
      padding-bottom: 20px;
      .chart-part {
        margin: 0 auto;
      }
      .table-part {
        width: 100%;
      }
    }
    .bar__container {
      .bar__chart {
        min-height: auto;
      }
    }
    .timeseries__container {
      height: 100%;
      align-items: flex-start;
      .timeseries__chart {
        height: 100%;
      }
    }
  }
}
