@import '../../../assets/scss/variables';

.main-video-button {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  border-radius: 70px;
  color: $gray-color-1;
  background-color: $color-white;
  box-shadow: $shadow-button;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  &:not(:disabled):hover {
    color: $gray-color-body;
    background-color: $gray-color-5;
  }
  &:not(:disabled):active,
  &.active {
    padding: 10px;
    color: $color-white;
    background-color: $accent-color-1;
    border: 2px solid $color-white;
    &:hover {
      color: $color-white;
      background-color: $accent-color-1;
    }
  }
  &:disabled {
    opacity: 0.5;
  }
  svg {
    width: 20px;
    height: 20px;
  }
}
