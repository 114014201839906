@import '../../../assets/scss/variables';

.medium-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 16px;
  color: $gray-color-body;

  &.red {
    color: $accent-color-1;
    &:hover {
      background-color: $accent-color-5;
    }
    &:active {
      color: $accent-color-3;
      background-color: $accent-color-4;
    }
  }

  &.gray {
    color: $gray-color-tertiary;
    &:hover {
      color: $gray-color-body;
      background-color: $gray-color-6;
    }
    &:active {
      color: $gray-color-body;
      background-color: $gray-color-5;
    }
  }

  &.loading {
    background-color: $gray-color-6;
  }

  &:hover {
    background-color: $gray-color-6;
  }
  &:active {
    background-color: $gray-color-4;
  }
  &:disabled {
    opacity: 0.5;
    &:hover {
      background-color: transparent;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
