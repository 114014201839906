@import '../variables.scss';

.custom-table {
  &__container {
    width: 100%;
    &.inner-scroll {
      overflow-y: auto;
    }
  }

  &__wrapper {
    width: 100%;
    border-radius: 18px;
    border: 1px solid $border-gray-color;
    overflow-x: auto;
  }

  &__table {
    width: 100%;
  }

  &__thead {
    border-bottom: 1px solid $border-gray-color;
    font-size: 12px;
    font-weight: 700;
    color: $gray-color-tertiary;
  }

  &__header-cell {
    padding: 8px 16px 7px 8px;
    vertical-align: middle;
    &:first-child {
      padding-left: 28px;
    }
    &:last-child {
      padding-right: 28px;
    }
    &.bordered {
      border-right: 1px solid $border-gray-color;
      & ~ th:not(:last-child) {
        border-right: 2px dashed $border-gray-color;
      }
    }
    &.sortable {
      cursor: pointer;
    }
    &__sort-icon {
      display: inline-block;
      margin-left: 4px;
      svg {
        width: 12px !important;
        height: 12px !important;
      }
      &.active {
        svg {
          color: $accent-color-1;
        }
      }
    }
  }

  &__tbody {
    &-row {
      height: 42px;
      border-bottom: 1px solid $border-gray-color;
      &:last-child {
        border-bottom: none;
      }
      &.selectable {
        &:hover {
          background-color: rgba($gray-color-6, 0.5);
        }
      }
      &.selected {
        position: relative;
        background-color: $accent-color-8;
        &:hover {
          background-color: $accent-color-8;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 4px;
          background-color: $accent-color-1;
        }
      }

      &.subRow {
        height: auto;

        &:not(.last-row) {
          border-bottom: none;
        }
      }

      &.innerTHead {
        height: auto;
        border-bottom: 1px solid $border-gray-color;
        font-size: 12px;
        font-weight: 700;
        color: $gray-color-tertiary;
        background-color: #F2F2F2;

        .custom-table__body-cell {
          padding: 5px 16px 6px 8px;
        }
      }
    }
  }

  &__body-cell {
    padding: 9px 16px 9px 8px;
    vertical-align: top;
    font-size: 14px;
    font-weight: 400;
    &:first-child {
      padding-left: 28px;
    }
    &:last-child {
      padding-right: 28px;
    }
    &.bordered {
      border-right: 1px solid $border-gray-color;
      & ~ td:not(:last-of-type) {
        border-right: 2px dashed $border-gray-color;
      }
    }
  }

  &__footer-cell {
    padding: 9px 16px 9px 8px;
    vertical-align: top;
    height: 42px;
    &:first-child {
      padding-left: 28px;
    }
    &:last-child {
      padding-right: 28px;
    }
  }
  &__tfoot {
    border-top: 1px solid $border-gray-color;
    font-weight: 600;
    color: $gray-color-body;
  }

  &__menu-cell {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    column-gap: 24px;
    &__button {
      padding: 0;
      color: $gray-color-tertiary;
      &-red {
        color: $accent-color-1;
      }
    }
    &__download-btn {
      width: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 6px;
      &.loading {
        button {
          color: $accent-color-1;
        }
      }
    }
  }

  &__bold-text {
    font-weight: 600;
  }
}
