@import "../../../assets/scss/variables";

.inner-scrollable-table {
  border-bottom: 1px solid #e3e3e3;

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  * {
    border: none !important;
    border-radius: 0 !important;
  }

  thead {
    background-color: #F8F8F8;
  }
}


