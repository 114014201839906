@import '../../../assets/scss/variables';

.time-range__dropdown-menu {
  box-shadow: $shadow-context-menu;
  padding: 16px;
  overflow: unset;
  z-index: 10000;
  background-color: #fff;

  .content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
  }

  .time-range__container {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .time-control-with-options.time-control-with-options__datepicker {
    width: 95px;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
  }
}
