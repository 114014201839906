@import '../../assets/scss/variables';

.nav-bar {
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  height: 36px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1px;
  border-radius: $border-radius-primary;
  background-color: $gray-color-6;
  list-style: none;

  .nav-item {
    height: 100%;
    position: relative;
    padding: 7px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-primary;
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }

    &:not(.active):hover {
      color: red;
      background-color: $gray-color-4;
    }

    &.active {
      color: white;
      background-color: $red;
      cursor: default;
    }

    &.tab-separator {
      &::after {
        content: '';
        position: absolute;
        right: -1px;
        width: 1px;
        height: 20px;
        background-color: $border-gray-color;
      }
    }
  }
}

@media (max-width: $xs) {
  .nav-bar {
    position: static;
    width: 100%;
    transform: none;
    &__container {
      width: calc(100vw - 20px);
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__custom-width {
      width: max-content;
    }
    &:not(.nav-bar__custom-width) {
      .nav-item {
        box-sizing: border-box;
        width: 100% !important;
      }
    }
  }
}
