@import '../../assets/scss/variables';

.preview__container {
  width: 320px;
  height: 240px;
  background-color: black;
  border-radius: $border-radius-primary;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  .image-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-pink;
    z-index: 1;
    &.md {
      color: white;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    svg {
      width: 28px;
      height: 28px;
    }
  }

  .camera-preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
