@import '../../../assets/scss/variables.scss';

.google-visualization-tooltip > ul > li {
  display: flex;
  width: max-content;
  height: 15px;
}
.chart-tooltip {
  width: max-content;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  div {
    font-weight: 400;
  }
  &__content {
    display: flex;
    align-items: center;
  }
  &__color {
    width: 10px;
    height: 10px;
    margin-right: 2px;
  }
}
