@import '../../../assets/scss/variables';

.simple-select {
  position: relative;
  height: 36px;
  &.opened {
    .filter__toggle {
      background-color: $gray-color-4;
      &:hover {
        background-color: $gray-color-4;
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    .filter__toggle {
      &:hover {
        background-color: $gray-color-6;
      }
    }
  }

  .filter__toggle {
    height: 100%;
    padding: 6px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: $border-radius-primary;
    background-color: $gray-color-6;
    line-height: 1;
    cursor: default;
    &:hover {
      background-color: $gray-color-5;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .filter__label {
      flex-grow: 1;
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
      color: $gray-color-body;
    }
  }

  .filter__dropdown-menu {
    position: absolute;
    top: 40px;
    border-radius: $border-radius-dropdown-list;
    background-color: white;
    box-shadow: $shadow-context-menu;
    z-index: 10;

    &__options {
      max-height: 300px;
      padding: 4px;
      overflow: auto;
      .option {
        padding: 9px 12px 9px 12px;
        white-space: nowrap;
        border-radius: 4px;
        cursor: default;
        &:hover {
          background-color: $gray-color-6;
        }
        &.active {
          background-color: $gray-color-5;
        }
      }
    }
  }
}
