@import '../../../assets/scss/variables';

.form-select-filter {
  position: relative;
  height: 44px;
  &.opened {
    .filter__toggle {
      background-color: $gray-color-4;
      &:hover {
        background-color: $gray-color-4;
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    .filter__toggle {
      &:hover {
        background-color: $gray-color-6;
      }
    }
  }

  .filter__toggle {
    height: 100%;
    padding: 5px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: $border-radius-primary;
    background-color: $gray-color-6;
    line-height: 1;
    cursor: default;
    &:hover {
      background-color: $gray-color-5;
    }

    svg {
      width: 20px;
      height: 20px;
    }
    .filter__label-container {
      display: flex;
      flex-direction: column;
      width: calc(100% - 25px);
      .filter__label {
        font-size: 16px;
        font-weight: 400;
        line-height: 17.5px;
        white-space: nowrap;
        color: $gray-color-body;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
      }
      .filter__title {
        color: $gray-color-tertiary;
        font-weight: 400;
        font-size: 16px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        &.selected {
          font-size: 12px;
          line-height: 15px;
          pointer-events: none;
          user-select: none;
        }
      }
    }
  }
}

.filter__dropdown-menu {
  position: absolute;
  top: 46px;
  border-radius: $border-radius-dropdown-list;
  background-color: $second-background-color;
  box-shadow: $shadow-context-menu;
  z-index: 10000;

  &__search {
    height: 36px;
    padding: 8px 4px 8px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
    border-bottom: 1px solid $border-gray-color;
    svg {
      width: 20px;
      height: 20px;
      opacity: 0.5;
    }
    input {
      height: 18px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &__options {
    max-height: calc(var(--data-max-height) - 10px);
    padding: 4px;
    overflow: auto;

    &.searchable {
      max-height: calc(var(--data-max-height) - 46px);
    }

    .option {
      padding: 9px 12px 9px 12px;
      white-space: nowrap;
      border-radius: 4px;
      cursor: default;
      &:hover {
        background-color: $gray-color-6;
      }
      &.active {
        background-color: $gray-color-5;
      }
    }
  }
}
