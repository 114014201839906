@import '../../assets/scss/variables.scss';

.digital-clock {
  min-width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;

  .current-time {
    font-size: 32px;
    white-space: nowrap;
  }
}

@media (max-width: $xs) {
  .digital-clock {
    .current-time {
      font-size: 28px !important;
    }
  }
}
